import React, {
  FC,
  useState,
  ReactNode,
  useRef,
  MouseEvent,
  useEffect,
} from 'react';

import styled from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import ChartComponent from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import Filters from '../../components/Filters/Filters';
import UpdateWallet from '../../dialogs/UpdateWallet';
import Select from '../../components/Select/Select';

import assets from '../../assets';
import theme from '../../theme';
import { benjiPerformance as benjiPerformanceApi } from '../../http';
import { IBenjiPerformance } from '../../types/interfaces';

const Performance: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const [filters, setFilters] = useState({
    game: '',
  });

  const games = useSelector((state: RootState) => state.games.games);
  const walletAddress = useSelector(
    (state: RootState) => state.auth.walletAccounts[0].wallet_address
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [benjiPerformance, setBenjiPerformance] =
    useState<IBenjiPerformance | null>(null);

  const [benji, setBenji] = useState(false);

  const [search, setSearch] = useState('');

  const HandleFilters = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };
  // eslint-disable-next-line
  const getRank = (rank: number): ReactNode => {
    if (rank === 1)
      return (
        <p className='font-size-24 first'>
          {rank}
          <span className='font-size-12'>st</span>
        </p>
      );
    else if (rank === 2 || rank === 3) {
      return (
        <p
          className={classNames(
            'font-size-24',
            rank === 2 && 'second',
            rank === 3 && 'third'
          )}
        >
          {rank}
          <span className='font-size-12'>nd</span>
        </p>
      );
    } else {
      return (
        <p className='font-size-24 nth'>
          {rank}
          <span className='font-size-12'>th</span>
        </p>
      );
    }
  };

  const tableMenus = useRef<any>([]);

  const addToRef = (e: any) => {
    if (e && !tableMenus.current.includes(e)) tableMenus.current.push(e);
  };

  const handleMenuClick = (e: MouseEvent<HTMLImageElement>, index: number) => {
    const boundries = e.currentTarget.getBoundingClientRect();
    const menu: HTMLDivElement = tableMenus.current[index];

    const top = boundries.top + window.scrollY;
    const left =
      window.innerWidth < boundries.left
        ? window.innerWidth - 60
        : boundries.left;

    if (menu) {
      if (menu.classList.contains('show')) {
        menu.classList.remove('show');
        return;
      }
      tableMenus.current.forEach((e: HTMLDivElement) =>
        e.classList.remove('show')
      );
      menu.classList.add('show');
      menu.style.left = `${left}px`;
      menu.style.top = `${top}px`;
    }
  };

  const handleCloseMenu = (index: number) => {
    const menu: HTMLDivElement = tableMenus.current[index];
    menu.classList.remove('show');
  };

  const handleGetBenjiData = async () => {
    try {
      if (walletAddress) {
        const { data } = await benjiPerformanceApi(walletAddress);
        setBenjiPerformance(data);
      }
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const options: ApexOptions = {
    chart: {
      type: 'area',
      toolbar: {
        show: false,
      },
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      animations: {
        easing: 'linear',
      },
      events: {
        mounted: chart => chart.windowResizeHandler(),
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: [theme.palette.secondary],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    colors: [theme.palette.primary, theme.palette.secondary],
    legend: {
      show: false,
    },
    grid: {
      borderColor: '#ffffff33',
      xaxis: {
        lines: {},
      },
    },
    xaxis: {
      type: 'category',
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      tickAmount: 10,
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
  };

  const series = [
    {
      name: 'series1',
      data: [],
    },
  ];

  useEffect(() => {
    if (games.length) {
      setFilters(prev => ({ ...prev, game: games[0].id }));
    }
  }, [games]);

  useEffect(() => {
    if (
      filters.game === games.find(game => game.name === 'Benji Bananas')?.id
    ) {
      handleGetBenjiData();
      setBenji(true);
    } else {
      setBenji(false);
    }
  }, [filters]);

  useEffect(() => {
    dispatch.games.getAllGames();
  }, []);

  return (
    <>
      <Padding>
        <h1 className='title'>Performance</h1>

        <Filters search={search} setSearch={setSearch}>
          <Select
            name='game'
            title='Select game'
            onChange={HandleFilters}
            options={games.map(game => ({ label: game.name, value: game.id }))}
            value={filters.game}
          />
        </Filters>
        <Grid>
          <Earnings className='earnings'>
            <div className='row space-between'>
              <h2 className='title'>Top Earnings</h2>
            </div>
            {/* <div className="row">
              <img src={assets.riot} alt='' />
              <div>
                <p className="font-size-20">
                  0 <span className="font-size-12">RIOT</span>
                </p>
                <p className="font-size-10" style={{ opacity: "0.6" }}>
                  ≈ 0 USD
                </p>
              </div>
            </div> */}
            <div className='row'>
              <img src={benji ? assets.primate : assets.stzIcon} alt='' />
              <div>
                <p className='font-size-20'>
                  {(benji && benjiPerformance?.performanceStats[0]?.amount) ||
                    0}{' '}
                  <span className='font-size-12'>
                    {benji ? 'PRIMATE' : 'STZ'}
                  </span>
                </p>
                {/* <p className='font-size-10' style={{ opacity: '0.6' }}>
                  ≈ 0 USD
                </p> */}
              </div>
            </div>
            {/* <div className='row'>
              <img src={assets.stzIcon} alt='' />
              <div>
                <p className='font-size-20'>
                  0 <span className='font-size-12'>THC</span>
                </p>
                <p className='font-size-10' style={{ opacity: '0.6' }}>
                  ≈ 0 USD
                </p>
              </div>
            </div> */}
          </Earnings>
          <Graph className='graph'>
            <div className='row'>
              <div className='row-item'>
                <p
                  className='font-size-18'
                  style={{ fontFamily: 'Stalinist One' }}
                >
                  0%
                </p>
                <p className='font-size-10'>this week</p>
              </div>
              <div className='row-item'>
                <p
                  className='font-size-18'
                  style={{ fontFamily: 'Stalinist One' }}
                >
                  0%
                </p>
                <p className='font-size-10'>last week</p>
              </div>
            </div>

            <div>
              <ChartComponent
                options={options}
                series={series}
                type='line'
                height={195}
                width='100%'
              />
            </div>
          </Graph>
        </Grid>
        {false && (
          <TableContainer>
            <table>
              {/* Table Header */}
              <thead>
                <tr className='header-row'>
                  <th>Game Rank</th>
                  <th>Game</th>
                  <th>Earning</th>
                  <th>Period</th>
                  <th>Revenue Split</th>
                  <th>W/L Ratio</th>
                  <th>Time spent today</th>
                  <th>Time Spent This Month</th>
                  <th></th>
                </tr>
              </thead>

              {/* Table body */}
              <tbody>
                {Array.from({ length: 1 }, (_, i) => i).map((i, index) => (
                  <tr key={i}>
                    <td>
                      {/* {getRank(i + 1)} */}
                      -----
                    </td>
                    <td>
                      <img src={assets.riotRacers} alt='' />
                    </td>
                    <td>
                      <div className='row'>
                        <img src={assets.riot} alt='' />
                        <div>
                          <p className='font-size-14'>0 RIOT</p>
                          <p className='font-size-10'>≈ 0 USD</p>
                        </div>
                      </div>
                    </td>
                    <td>---------</td>
                    <td>30/70</td>
                    <td>0/0</td>

                    <td>0 hrs</td>
                    <td>0 hrs</td>

                    <td>
                      <ClickAwayListener
                        onClickAway={() => handleCloseMenu(index)}
                      >
                        <div className='icon-button'>
                          <img
                            src={assets.moreVert}
                            alt=''
                            onClick={e => handleMenuClick(e, index)}
                          />
                          <div className='menu' ref={addToRef}>
                            <div
                              className='menu-item'
                              onClick={() => {
                                setOpenDialog(true);
                                // dispatch.dialogs.openDialog('updateWallet');
                                handleCloseMenu(index);
                              }}
                            >
                              <p className='font-size-12'>
                                Update Payout Wallet Address
                              </p>
                            </div>
                            {/* <div
                            className="menu-item"
                            onClick={() => {
                              // dispatch.dialogs.openDialog('updateWallet');

                              handleCloseMenu(index);
                            }}
                          >
                            <p className="font-size-12">Contact Team Captain</p>
                          </div> */}
                          </div>
                        </div>
                      </ClickAwayListener>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        )}

        {openDialog && (
          <UpdateWallet open={openDialog} setOpen={setOpenDialog} />
        )}
      </Padding>
    </>
  );
};

export default Performance;

const Padding = styled.div`
  padding-top: 40px;
  padding-inline: 30px;
  width: 100%;
  flex-grow: 1;

  @media only screen and (max-width: 700px) {
    padding-top: 40px;
    padding-inline: 25px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 24px;
  margin-top: 30px;
  @media only screen and (max-width: 756px) {
    grid-template-columns: 1fr;
  }
`;

const Earnings = styled.div`
  padding: 8px 20px;
  background-color: #121212;
  & .row {
    display: flex;
    padding: 16px 20px;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #ffffff33;
    &:last-of-type {
      border-bottom: none;
    }

    &.space-between {
      justify-content: space-between;
    }

    & img {
      max-width: 30px;
    }
  }
`;

const Graph = styled.div`
  background-color: #121212;
  padding: 24px 30px;

  & .row {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: flex-end;

    & .row-item {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      & p:last-of-type {
        opacity: 0.6;
      }
    }
  }

  @media only screen and (min-width: 1000px) {
    & .row {
      padding-right: 100px;
      & .row-item {
        min-width: 184px;
      }
    }
  }
`;
// eslint-disable-next-line
const Menu = styled.div`
  position: relative;
  & button {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: #000000;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    height: 26px;
    padding: 0px 12px;
  }
`;
// eslint-disable-next-line
const MenuPopup = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(calc(100% + 10px));
  background-color: #000000;
  width: 120px;
  padding: 5px 0px;
  & .menu-item {
    padding: 5px 10px;
    cursor: pointer;
    &:hover {
      background-color: #121212;
    }
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
  border: 1px solid #ffffff33;
  padding-bottom: 10px;
  width: 100%;
  margin-block: 60px;
  border-radius: 4px;

  & table {
    border-collapse: collapse;
    min-width: 950px;
    width: 100%;
    & tbody {
      padding-inline: 10px;
    }
    & .header-row {
      height: 40px;
      background-color: #0e0e0e;
      & th {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        opacity: 0.6;
        padding-top: 4px;
        border-bottom: 1px solid #ffffff33;
        text-align: left;
        &:first-of-type {
          padding-left: 32px;
        }
      }
    }
    & tr {
      height: 60px;
    }
    & td {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      border-bottom: 1px solid #ffffff33;
      padding-block: 12px;
      &:first-of-type {
        padding-left: 32px;
      }

      & p.font-size-24 {
        font-family: 'Stalinist One';

        & span {
          font-family: inherit;
        }
        &.first {
          background: linear-gradient(180deg, #ffe99d 0%, #f7ae3f 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }

        &.second {
          background: linear-gradient(180deg, #bcb8dd 0%, #6667a8 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }

        &.third {
          background: linear-gradient(180deg, #f3b785 0%, #d37e47 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }
      & p.second {
        font-family: 'Stalinist One';
        background: linear-gradient(180deg, #bcb8dd 0%, #6667a8 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;

        & span {
          font-family: inherit;
        }
      }
      & p.third {
        font-family: 'Stalinist One';
        background: linear-gradient(180deg, #f3b785 0%, #d37e47 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;

        & span {
          font-family: inherit;
        }
      }
      & p.nth {
        font-family: 'Stalinist One';

        & span {
          font-family: inherit;
        }
      }

      & img {
        max-height: 40px;
        max-width: 105px;
        /* margin-left: 10px; */
      }

      & .row {
        display: flex;
        align-items: center;
        gap: 10px;

        & img {
          width: 20px;
        }
      }

      & .icon-button {
        & img {
          padding-inline: 5px;
          opacity: 0.4;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
        & .menu {
          position: absolute;
          width: 200px;
          background: #0e0e0e;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          transform: translate(-90%, -115%);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          display: none;
          & .menu-item {
            padding: 10px 5px;
            cursor: pointer;
            &:hover {
              background-color: #000000;
            }
          }
          & p {
            opacity: 0.6;
          }
          &.show {
            display: block;
          }
        }
      }
    }
    & tr:last-child {
      & td {
        border: none;
      }
    }
  }
`;
