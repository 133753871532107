import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Router from './Router';
import { Dispatch } from './store';
import GlobalStyles from './theme/GlobalStyles';

function App() {
  const dispatch = useDispatch<Dispatch>();

  // const loadingUser = useSelector((state: RootState) => state.auth.loadingUser);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch.auth.getUser();
    } else {
      dispatch.auth.setLoadingUser(false);
    }
  }, []);

  // if (loadingUser) {
  //   // maybe we can show a loading screen here
  //   return null;
  // }

  return (
    <>
      <GlobalStyles />
      <ToastContainer
        position='top-center'
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme='colored'
      />
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </>
  );
}

export default App;
