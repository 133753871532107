import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  IWalletAccount,
  IAuthModal,
  ILoggedInUser,
  IUpdateAccount,
} from '../../types/interfaces';
import {
  signup,
  emailVerification,
  login as loginApi,
  forgotPassword as forgotPasswordApi,
  recoverPassword,
  getUserInfo,
  connectWallet as connectWalletApi,
  getWalletAccounts,
  updateUser,
  userImage,
  updatePayoutWallet,
  getNewVerificationCode,
} from '../../http';
import axios from 'axios';
import React from 'react';

export const auth = createModel<RootModel>()({
  name: 'auth',
  state: {
    isLoggedIn: !!localStorage.getItem('token'),
    user: null,
    varificatonToken: null,
    loading: false,
    loadingUser: true,
    walletAddress: '',
    walletAccounts: [],
    userEmail: '',
  } as IAuthModal,
  reducers: {
    setIsLoggedIn(state, payload: boolean) {
      state.isLoggedIn = payload;
    },
    setVerificationToken(state, payload: string) {
      state.varificatonToken = payload;
    },

    setLoading(state, payload: boolean) {
      state.loading = payload;
    },

    setLoadingUser(state, payload: boolean) {
      state.loadingUser = payload;
    },

    setUser(state, payload: ILoggedInUser) {
      state.user = payload;
    },
    logOut(state) {
      state.isLoggedIn = false;
      state.user = null;
      state.varificatonToken = null;
      state.walletAccounts = [];
      state.walletAddress = '';
      localStorage.removeItem('token');
    },
    setWalletAddress(state, payload: string) {
      state.walletAddress = payload;
    },
    setAccounts(state, payload: Array<IWalletAccount>) {
      state.walletAccounts = payload;
    },
    setUserEmail(state, payload: string) {
      state.userEmail = payload;
    },
  },
  effects: dispatch => ({
    async register(payload) {
      dispatch.auth.setLoading(true);
      try {
        const { data } = await signup(payload.data);
        dispatch.auth.setVerificationToken(data.token);
        dispatch.auth.setUserEmail(payload.data.email);
        payload.navigate('/account-confirmation');
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage: string = err.response
            ? err.response.data.message
            : '';
          //   setting errors
          payload.setErrors({
            ...payload.errors,
            email: '',
            confirm_passoword: '',
          });
          if (errorMessage === 'Email already taken.') {
            payload.setErrors({
              ...payload.errors,
              email: 'Email already taken.',
            });
          }
          if (errorMessage.includes('confirm_password')) {
            payload.setErrors({
              ...payload.errors,
              confirm_password: 'Repeat password should be same as password',
            });
          }
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },

    async confirmEmail({ code, token, setErrors, setValidated }) {
      dispatch.auth.setLoading(true);
      try {
        const { data } = await emailVerification(code, token);
        localStorage.setItem('token', data?.accessToken);
        setValidated(true);
      } catch (err) {
        setErrors({ code: '' });
        if (axios.isAxiosError(err)) {
          setErrors({ code: err.response?.data?.message || 'Invalid code.' });
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },

    async regenerateCode({
      email,
      setErrors,
    }: {
      email: string;
      setErrors: Function;
    }) {
      dispatch.auth.setLoading(true);
      try {
        const { data } = await getNewVerificationCode(email);
        dispatch.auth.setVerificationToken(data.token);
        setErrors({ code: '' });
      } catch (err) {
        setErrors({ code: '' });
        if (axios.isAxiosError(err)) {
          setErrors({
            code:
              err.response?.data?.message ||
              'Something went wrong! Please try again later.',
          });
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },

    async login({ formData, errors, setErrors, navigate }) {
      dispatch.auth.setLoading(true);
      try {
        const { data } = await loginApi({
          email: formData.email,
          password: formData.password,
        });
        const { accessToken, ...user } = data;

        dispatch.auth.setUser(user);
        dispatch.auth.setIsLoggedIn(true);
        localStorage.setItem('token', accessToken);
        localStorage.setItem('last_email', user.email);

        const { data: response }: { data: Array<IWalletAccount> } =
          await getWalletAccounts();
        dispatch.auth.setAccounts(response);

        if (response.length) {
          dispatch.auth.setWalletAddress(response[0].wallet_address);
        }
        navigate('/dashboard');
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage: string = err.response
            ? err.response.data.message
            : '';
          setErrors({ email: errorMessage });
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },

    async forgotPassword({ email, setError, setValidated }) {
      dispatch.auth.setLoading(true);
      try {
        const { data } = await forgotPasswordApi(email);
        dispatch.auth.setVerificationToken(data.token);
        dispatch.auth.setUserEmail(email);
        setValidated(true);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage: string = err.response
            ? err.response.data.message
            : '';
          setError(errorMessage);
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },

    async resetPassowrd({
      token,
      data: formData,
      setValidated,
      errors,
      setErrors,
    }) {
      dispatch.auth.setLoading(true);
      try {
        await recoverPassword(formData, token);
        setValidated(true);
      } catch (err) {
        setErrors({ ...errors, code: '', confirm_password: '' });
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message || '';
          if (errorMessage.includes('confirm_password')) {
            setErrors({
              ...errors,
              confirm_password: 'Repeat password is not same as new password',
            });
          } else {
            setErrors({ ...errors, code: errorMessage });
          }
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async getUser() {
      dispatch.auth.setLoadingUser(true);
      try {
        const { data } = await getUserInfo();
        console.log(data);
        dispatch.auth.setUser(data);
        const { data: response }: { data: Array<IWalletAccount> } =
          await getWalletAccounts();
        dispatch.auth.setAccounts(response);

        if (response.length) {
          dispatch.auth.setWalletAddress(response[0].wallet_address);
        }
      } catch (err) {
        console.error(err);
      } finally {
        dispatch.auth.setLoadingUser(false);
      }
    },
    async connectWallet({ public_address, setWalletDialog }) {
      try {
        dispatch.auth.setWalletAddress(public_address);
        await connectWalletApi(public_address);
        const { data } = await getWalletAccounts();
        dispatch.auth.setAccounts(data);
        setWalletDialog(true);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage: string = err.response
            ? err.response.data.message
            : '';
          if (errorMessage === 'Wallet already connected.') {
            try {
              const { data } = await getWalletAccounts();
              dispatch.auth.setAccounts(data);
            } catch (err) {
              console.log((err as any).message);
            }
          }
        }
      }
    },

    async addProfileData({ payload, image, navigate }) {
      try {
        dispatch.auth.setLoading(true);
        await Promise.all([updateUser(payload), image && userImage(image)]);
        localStorage.clear();
        navigate('/login');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },

    async updatePayoutWalletAddress(payload: {
      data: IUpdateAccount;
      setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    }) {
      try {
        dispatch.auth.setLoading(true);
        await updatePayoutWallet(payload.data);
        dispatch.auth.getUser();
        payload.setOpen(false);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
  }),
});
