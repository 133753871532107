import React, { ChangeEvent, FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { InferType, object, string } from 'yup';
import { FormErrors, validateData } from '../../utils/validations';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';

import LoginLayout from '../../layouts/LoginLayout';
import InputField from '../../components/InputField/InputField';
import Button from '../../components/Button/Button';
import theme from '../../theme';

const ConfirmAccount: FC = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const dispatch = useDispatch<Dispatch>();
  const [validated, setValidated] = useState(false);
  // eslint-disable-next-line
  const token = useSelector((state: RootState) => state.auth.varificatonToken);
  const email = useSelector((state: RootState) => state.auth.userEmail);
  const loading = useSelector((state: RootState) => state.auth.loading);

  const confirmSchema = object({
    code: string().required().label('Confirmation code'),
  });

  interface ConfirmData extends InferType<typeof confirmSchema> {}

  const [errors, setErrors] = useState<FormErrors<ConfirmData>>({
    code: '',
  });

  const [formData, setFormData] = useState<ConfirmData | { code: '' }>({
    code: '',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ code: e.target.value });
  };

  const handleValidate = async () => {
    setErrors({ code: '' });
    const { errors: validationErros } = await validateData<ConfirmData>(
      confirmSchema,
      formData
    );
    if (validationErros) {
      setErrors(validationErros);
      return;
    }
    // setValidated(true);
    dispatch.auth.confirmEmail({
      code: formData.code,
      token,
      setErrors,
      setValidated,
    });
  };

  const handleRegenerateCode = () => {
    dispatch.auth.regenerateCode({ email, setErrors });
  };

  return (
    <LoginLayout>
      <Container>
        {validated ? (
          <>
            <div className='flex-1'>
              <h1 className='title'>Thanks for verifying!</h1>
              <p className='font-size-14 mb'>
                It’s time to set up your 99Starz profile.
              </p>
            </div>
            <Button
              variant='primary'
              onClick={() => navigate('/profile-setup')}
            >
              Continue
            </Button>
          </>
        ) : (
          <>
            <div className='flex-1'>
              <h1 className='title'>Account confirmation</h1>
              <p className='font-size-14'>
                6-digit email confirmation code was send to your email adress{' '}
                {email}.
              </p>
              <InputField
                placeholder='Confirmation code'
                className='code'
                type='text'
                error={errors.code}
                onChange={handleChange}
                value={formData.code}
              />
            </div>
            {errors.code?.toLowerCase()?.includes('token expire') ? (
              <Button
                variant='primary'
                onClick={handleRegenerateCode}
                disabled={loading}
              >
                Get new code
              </Button>
            ) : (
              <Button
                variant='primary'
                onClick={handleValidate}
                disabled={loading}
              >
                Continue
              </Button>
            )}

            {/* Do not remove this */}
            {/* <div className='have-account'>
              <p className='font-size-14'>
                Having trouble?&nbsp;
                <Link to='#' className='anchor'>
                  Contact customer service
                </Link>
                .
              </p>
            </div> */}
          </>
        )}
      </Container>
    </LoginLayout>
  );
};

export default ConfirmAccount;

const Container = styled.div`
  flex: 1;
  /* min-height: 100%; */
  display: flex;
  justify-content: center;
  flex-direction: column;

  & h1 {
    margin-bottom: 20px;
  }
  & .code {
    margin-block: 60px;
  }
  & .mb {
    margin-bottom: 60px;
  }
  & .have-account {
    text-align: center;
    margin-top: 20px;
    & .anchor-secondary {
      color: ${theme.palette.secondary};
    }
  }

  @media only screen and (max-width: 700px) {
    & .flex-1 {
      flex-grow: 1;
    }
  }
`;
