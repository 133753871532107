import { JsonRpcProvider } from '@ethersproject/providers';
import { Contract } from 'ethers';
import { formatEther, formatUnits } from 'ethers/lib/utils';
import {
  mainnetRpc,
  appEnv,
  rinkebyRpc,
  stzContractAddress,
} from '../app.config';
import STZ_ABI from '../abis/STZ.json';

export const getRpcUrl = () => {
  if (appEnv === 'production') {
    return mainnetRpc;
  }

  return rinkebyRpc;
};

export const getEthBalance = async (address: string) => {
  const provider = new JsonRpcProvider(getRpcUrl());

  const balance = await provider.getBalance(address);

  return (+formatEther(balance)).toFixed(4);
};

export const getStzBalance = async (address: string) => {
  const provider = new JsonRpcProvider(getRpcUrl());

  const StzContract = new Contract(stzContractAddress!, STZ_ABI, provider);

  const balance = await StzContract.balanceOf(address);

  const decimals = await StzContract.decimals();

  return (+formatUnits(balance, decimals)).toFixed(4);
};
