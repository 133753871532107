import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../store';

import DialogLayout from './Dialog';
import Button from '../components/Button/Button';

import assets from '../assets';
import { useNavigate } from 'react-router-dom';

const TransferDialog: FC = () => {
  const open = useSelector((state: RootState) => state.dialogs.transfer);
  const walletAccounts = useSelector(
    (state: RootState) => state.auth.walletAccounts
  );

  const starzAddress = walletAccounts[0]?.starz_wallet_address;

  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  return (
    <DialogLayout open={open}>
      <Container>
        <div
          className='close-icon'
          onClick={() => {
            dispatch.dialogs.closeDialog('transfer');
            navigate('/dashboard/my-badge');
          }}
        >
          <img src={assets.closeIcon} alt='' />
        </div>
        <div className='content'>
          <h1 className='title'>Transfer succesful!</h1>
          <p className='font-size-14'>
            Badge is minted succesfuly.
            <span style={{ marginTop: '10px', display: 'block' }}>
              Check on OpenSea{' '}
              <a
                className='anchor'
                href={`https://testnets.opensea.io/${starzAddress}`}
                target='_blank'
                rel='noreferrer'
              >
                here
              </a>
            </span>
          </p>
          <div className='btn'>
            <Button
              variant='primary'
              onClick={() => {
                dispatch.dialogs.closeDialog('transfer');
                navigate('/dashboard/my-badge');
              }}
            >
              Complete
            </Button>
          </div>
        </div>
      </Container>
    </DialogLayout>
  );
};

export default TransferDialog;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 420px;
  & .close-icon {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    & img {
      cursor: pointer;
    }
  }
  & .content {
    margin-top: 6px;
    padding: 40px 30px;
    background-color: #121212;
    @media only screen and (max-width: 400px) {
      padding: 30px 20px;
    }

    & p {
      margin-block: 20px 40px;
    }

    & .btn {
      display: flex;
      justify-content: center;
    }
  }
`;
