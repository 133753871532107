import React, { FC, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import theme from '../../theme';

import ChartComponent from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

const Chart: FC = () => {
  const [tab, setTab] = useState(5);

  const options: ApexOptions = {
    chart: {
      height: 250,
      type: 'area',
      toolbar: {
        show: false,
      },
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      animations: {
        easing: 'linear',
      },
      events: {
        mounted: chart => chart.windowResizeHandler(),
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    colors: [theme.palette.primary, theme.palette.secondary],
    legend: {
      show: false,
    },
    grid: {
      borderColor: '#ffffff33',
      xaxis: {
        lines: {},
      },
    },
    xaxis: {
      type: 'datetime',
      categories: [
        '2018-09-19T00:00:00.000Z',
        '2018-09-19T01:30:00.000Z',
        '2018-09-19T02:30:00.000Z',
        '2018-09-19T03:30:00.000Z',
        '2018-09-19T04:30:00.000Z',
        '2018-09-19T05:30:00.000Z',
        '2018-09-19T06:30:00.000Z',
      ],
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
  };

  const series = [
    {
      name: 'series1',
      data: [31, 40, 28, 51, 42, 109, 100],
    },
    {
      name: 'series2',
      data: [11, 32, 45, 32, 34, 52, 41],
    },
  ];

  return (
    <Container>
      <Tabs>
        <div
          className={classNames('tab font-size-12', tab === 1 && 'active')}
          onClick={() => setTab(1)}
        >
          Today
        </div>
        <div
          className={classNames('tab font-size-12', tab === 2 && 'active')}
          onClick={() => setTab(2)}
        >
          Week
        </div>
        <div
          className={classNames('tab font-size-12', tab === 3 && 'active')}
          onClick={() => setTab(3)}
        >
          Month
        </div>
        <div
          className={classNames('tab font-size-12', tab === 4 && 'active')}
          onClick={() => setTab(4)}
        >
          year
        </div>
        <div
          className={classNames('tab font-size-12', tab === 5 && 'active')}
          onClick={() => setTab(5)}
        >
          All
        </div>
      </Tabs>

      <div className='chart-wrapper'>
        <ChartComponent
          options={options}
          series={series}
          width='100%'
          type='area'
          height={250}
        />
      </div>
    </Container>
  );
};

export default Chart;

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  & .chart-wrapper {
    width: 100%;
    height: 250px;
    display: block;
    overflow: hidden;
    text-align: right;
    margin-left: auto;
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;

  & .tab {
    padding: 5px 15px;
    cursor: pointer;
    &.active {
      font-weight: 600;
      background-color: ${theme.palette.primary};
      clip-path: polygon(
        0 0,
        0 100%,
        100% 100%,
        100% 10px,
        calc(100% - 10px) 0
      );
    }
  }
  @media only screen and (max-width: 700px) {
    grid-gap: 5px;
  }
`;
