import React, { FC, useState } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Navigate } from 'react-router-dom';

import BadgeMintDialog from '../../dialogs/BadgeMintDialog';
// import Button from '../../components/Button/Button';

import assets from '../../assets';
import theme from '../../theme';

const Home: FC = () => {
  const game = useSelector((state: RootState) => state.games.selectedGame);
  const selectedBadge = useSelector(
    (state: RootState) => state.badges.selected
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [stzCount, setStzCount] = useState(0);

  if (!game || !selectedBadge) {
    return <Navigate to='/dashboard/games' />;
  }
  return (
    <div style={{ flexGrow: 1 }}>
      <Container>
        <Padding>
          <Grid>
            {/* Bronze Badge */}
            <BadgeCard>
              <div className='badge-logo'>
                <img src={assets.bronzeBadge} alt='' />
              </div>
              <Content>
                <p className='font-size-18 title text-center '>
                  Rise to the occasion!
                </p>
                <p className='font-size-14 description text-center'>
                  The Bronze badge provides starter gaming assets — helping you
                  achieve victory.
                  <br />
                  <br />
                </p>
                <STZHoldCount>
                  <img src={assets.stzIcon} alt='' />
                  <div>
                    <p className='font-size-24'>
                      {game?.minimum_bronze_badge_token}{' '}
                      <span className='font-size-16'>STZ</span>
                    </p>
                    <p className='font-size-14'>required to hold</p>
                  </div>
                </STZHoldCount>
                <Divider />

                <div className='game-icons'>
                  <div className='game'>
                    <img src={assets.cryptoMini} alt='' className='game-icon' />
                  </div>
                  <div className='game'>
                    <img src={assets.axieMini} alt='' className='game-icon' />
                  </div>
                  <div className='game'>
                    <img src={assets.sorareMini} alt='' className='game-icon' />
                  </div>
                </div>

                <div className='text-center'>
                  <p
                    className={classNames(
                      'font-size-14 title text-center link',
                      selectedBadge?.name !== 'Bronze Badge' && 'disabled'
                    )}
                    onClick={() => {
                      setStzCount(game?.minimum_bronze_badge_token || 0);
                      setOpenDialog(true);
                    }}
                  >
                    Mint the badge
                  </p>
                </div>
              </Content>
            </BadgeCard>
            {/* Silver Badge */}
            <BadgeCard>
              <div className='badge-logo'>
                <img src={assets.silverBadge} alt='' />
              </div>
              <Content>
                <p className='font-size-18 title text-center '>
                  Carry the day!
                </p>
                <p className='font-size-14 description text-center'>
                  The Silver badge allows you experience more of 99Starz —
                  increasing your pool of assets and available games.
                  <br />
                  <br />
                </p>
                <STZHoldCount>
                  <img src={assets.stzIcon} alt='' />
                  <div>
                    <p className='font-size-24'>
                      {game?.minimum_silver_badge_token}{' '}
                      <span className='font-size-16'>STZ</span>
                    </p>
                    <p className='font-size-14'>required to hold</p>
                  </div>
                </STZHoldCount>
                <Divider />

                <div className='game-icons'>
                  <div className='game'>
                    <img src={assets.cryptoMini} alt='' className='game-icon' />
                  </div>
                  <div className='game'>
                    <img src={assets.axieMini} alt='' className='game-icon' />
                  </div>
                  <div className='game'>
                    <img src={assets.sorareMini} alt='' className='game-icon' />
                  </div>
                </div>

                <div className='text-center'>
                  <p
                    className={classNames(
                      'font-size-14 title text-center link',
                      selectedBadge?.name !== 'Silver Badge' && 'disabled'
                    )}
                    onClick={() => {
                      setStzCount(game?.minimum_silver_badge_token || 0);
                      setOpenDialog(true);
                    }}
                  >
                    Mint the badge
                  </p>
                </div>
              </Content>
            </BadgeCard>
            {/* Gold Badge */}
            <BadgeCard>
              <div className='badge-logo'>
                <img src={assets.goldBadge} alt='' />
              </div>
              <Content>
                <p className='font-size-18 title text-center '>
                  Become the master!
                </p>
                <p className='font-size-14 description text-center'>
                  The Gold badge gives you access to the best possible assets —
                  allowing you to dominate the battlefield and earn better
                  rewards.
                </p>
                <STZHoldCount>
                  <img src={assets.stzIcon} alt='' />
                  <div>
                    <p className='font-size-24'>
                      {game?.minimum_gold_badge_token}{' '}
                      <span className='font-size-16'>STZ</span>
                    </p>
                    <p className='font-size-14'>required to hold</p>
                  </div>
                </STZHoldCount>
                <Divider />

                <div className='game-icons'>
                  <div className='game'>
                    <img src={assets.cryptoMini} alt='' className='game-icon' />
                  </div>
                  <div className='game'>
                    <img src={assets.axieMini} alt='' className='game-icon' />
                  </div>
                  <div className='game'>
                    <img src={assets.sorareMini} alt='' className='game-icon' />
                  </div>
                </div>
                <div className='text-center'>
                  <p
                    className={classNames(
                      'font-size-14 title text-center link',
                      selectedBadge?.name !== 'Gold Badge' && 'disabled'
                    )}
                    onClick={() => {
                      setStzCount(game?.minimum_gold_badge_token || 0);
                      setOpenDialog(true);
                    }}
                  >
                    Mint the badge
                  </p>
                </div>
              </Content>
            </BadgeCard>
          </Grid>
          <div className='btn-container'>
            {/* <Button variant='primary'>Apply for Captain Position</Button> */}
          </div>
        </Padding>
      </Container>

      {openDialog && (
        <BadgeMintDialog
          stzCount={stzCount}
          open={openDialog}
          setOpen={setOpenDialog}
        />
      )}
    </div>
  );
};

export default Home;

const Container = styled.div`
  flex-grow: 1;
  min-height: calc(100vh - 80px);
  background-image: url(${assets.wallpaper});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
`;

const Padding = styled.div`
  padding-top: 72px;
  padding-inline: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  gap: 40px;

  @media only screen and (max-width: 700px) {
    padding-top: 40px;
    padding-inline: 25px;
    padding-bottom: 100px;
  }
`;

const Grid = styled.div`
  /* width: 100%; */
  /* max-width: 1300px; */
  margin-top: 80px;
  display: grid;
  align-items: stretch;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 124px 24px;
  @media only screen and (max-width: 1050px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const BadgeCard = styled.div`
  background-color: #121212;
  border: 1px solid;
  border-image: linear-gradient(180deg, ${theme.palette.primary}, #000000) 1;
  padding-inline: 46px;
  padding-bottom: 40px;
  transition: 0.4s ease;
  cursor: pointer;
  &:hover {
    border-image: linear-gradient(
        180deg,
        ${theme.palette.primary},
        ${theme.palette.primary}
      )
      1;

    & .badge-logo {
      & img {
        transform: scale(1.15);
      }
    }
  }

  & .badge-logo {
    margin-inline: auto;
    display: flex;
    justify-content: center;
    transform: translateY(-80px);
    & img {
      transition: 0.4s ease;
      width: 100%;
      max-width: 250px;
      min-width: 150px;
    }
  }
  @media only screen and (max-width: 450px) {
    padding-inline: 46px;
  }
`;

const Content = styled.div`
  margin-top: -40px;

  & .description {
    margin-block: 20px 30px;
    max-width: 32ch;
    margin-inline: auto;
  }

  & p.link {
    cursor: pointer;
    opacity: 0.4;
    display: inline-block;
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
      cursor: not-allowed;
    }
    &:hover {
      opacity: 1;
    }
  }

  & .game-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    margin-block: 30px 40px;

    & .game {
      height: 40px;
      width: 40px;
      padding: 8px;
      background-color: #ffffff1a;
      border-radius: 8px;
      & img {
        width: 100%;
      }
    }
  }
`;

const Divider = styled.div`
  border-top: 1px solid #ffffff33;
`;

const STZHoldCount = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 17px 24px;
  margin-inline: auto;
  background-color: #000000;
  border-radius: 6px;
  margin-bottom: 24px;
  & img {
    height: 48px;
    width: 48px;
    border-radius: 50%;
  }
  & p.font-size-14 {
    opacity: 0.6;
  }
`;
