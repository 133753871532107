import React, { FC, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../store';
import { useNavigate } from 'react-router-dom';

import Button from '../components/Button/Button';
import DialogLayout from './Dialog';

import { mint } from '../http';

import assets from '../assets';
import axios from 'axios';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  stzCount: number;
}

const BadgeMintDialog: FC<IProps> = ({ open, setOpen, stzCount }) => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const walletAddress = useSelector(
    (state: RootState) => state.auth.walletAddress
  );
  const selectedBadge = useSelector(
    (state: RootState) => state.badges.selected
  );

  const [loading, setLoading] = useState(false);

  const handleMintBadge = async () => {
    try {
      setLoading(true);
      if (selectedBadge) {
        const data = {
          badge_id: selectedBadge?.id,
          badge_type: selectedBadge?.badge_type,
          public_address: walletAddress,
        };
        await mint(data);
        setOpen(false);
        navigate('/dashboard/games');
        dispatch.feedbackModal.open({
          title: `${selectedBadge.name} minted successfully!`,
          subtitle:
            "The path to victory is paved by dedication! By minting this badge you've proven yourself to be a core part of the 99Starz guild — additional bonuses have been unlocked!",
        });
      }
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data.message;
        dispatch.feedbackModal.open({
          title: `Error`,
          subtitle: `${errorMessage}. If you have enough assets in another wallet Please update the wallet from the profile tab or send the assets to this one to make sure the requirements are met.`,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <DialogLayout open={open}>
      <Container>
        <div className='close-icon-container'>
          <img
            src={assets.closeIcon}
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(false)}
            alt=''
          />
        </div>
        <Content>
          <p className='font-size-24 title'>Before minting the Badge</p>

          <p className='font-size-14 description'>
            Make sure your scholar wallet contains the required amount of STZ
            tokens and ETH to cover the minting gas fees. The minting process
            will not require you to spend your STZ tokens.
          </p>

          <div className='row'>
            <STZCount>
              <div className='row'>
                <img
                  src={assets.stzIcon}
                  style={{ height: '48px', width: '48px' }}
                  alt=''
                />
                <div>
                  <p className='font-size-24 bold'>
                    {stzCount} <span className='font-size-16 bold'>STZ</span>
                  </p>

                  {/* <p className='font-size-16' style={{ opacity: '0.6' }}>
                    ≈ 302.93500 USD
                  </p> */}
                </div>
              </div>
            </STZCount>
            <ETHCount>
              <div className='row'>
                <img
                  src={assets.ETH}
                  style={{ height: '48px', width: '48px' }}
                  alt=''
                />
                <div>
                  <p className='font-size-24 bold'>
                    0 <span className='font-size-16 bold'>ETH</span>
                  </p>

                  {/* <p className='font-size-16' style={{ opacity: '0.6' }}>
                    ≈ 17,04 USD
                  </p> */}
                </div>
              </div>
            </ETHCount>
          </div>

          <p className='font-size-14 stzHold'>
            Your badge will be active as long as your scholar wallet contains
            the required amount of STZ tokens.
          </p>

          <ButtonContainer>
            <Button variant='secondary' onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={handleMintBadge}
              disabled={loading}
            >
              Mint Badge
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default BadgeMintDialog;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  & .close-icon-container {
    display: flex;
    justify-content: flex-end;
    padding-block: 6px;
  }
`;

const Content = styled.div`
  background: #121212;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
  padding: 40px 28px;

  & .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
  }

  & p.description {
    margin-block: 24px 30px;
  }

  & p.stzHold {
    margin-block: 30px 40px;
  }
`;

const STZCount = styled.div`
  display: flex;
  justify-content: center;
  width: 260px;

  & .row {
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: #000000;
    padding: 16px;
    border-radius: 6px;
    width: 100%;
    justify-content: flex-start;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media only screen and (max-width: 460px) {
    flex-direction: column;
    & button {
      width: 100%;
    }
  }
`;

const ETHCount = styled.div`
  display: flex;
  justify-content: center;
  width: 260px;

  & .row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    background-color: #000000;
    width: 100%;
    padding: 16px;
    border-radius: 6px;
  }
`;
