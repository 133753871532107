import React, { FC, useEffect, useState } from 'react';
// import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { resizerUrl } from '../../app.config';
import { getNftImage } from '../../utils/nts';
import { ClipLoader } from 'react-spinners';

interface Props {
  nftData: any;
  size: number;
  container?: boolean;
}

const NftImage: FC<Props> = ({ nftData, size = 60, container }) => {
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const nftImage = await getNftImage(nftData);
      setImage(`${resizerUrl}/image?url=${nftImage}&size=${2 * size}`);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ImageWrapper
        data-tip={`${nftData?.name} #${nftData?.token_id}`}
        style={{
          height: container ? '100%' : size,
          width: container ? '100%' : size,
        }}
      >
        <ClipLoader
          color='white'
          size={20}
          speedMultiplier={0.5}
          loading={loading}
        />
        <img
          style={{
            display: loading ? 'none' : 'block',
            height: container ? '100%' : '',
            objectFit: container ? 'cover' : 'initial',
          }}
          src={image ? image : ''}
          alt={`${nftData?.name} #${nftData?.token_id}`}
          onLoad={() => setLoading(false)}
        />
      </ImageWrapper>
      {/* <ReactTooltip effect='float' type='dark' place='top' /> */}
    </>
  );
};

export default NftImage;

const ImageWrapper = styled.div`
  margin-top: 1px;
  border-radius: 6px;
  display: grid;
  place-items: center;
  & img {
    width: 100%;
  }
`;
