const assets = {
  hero: require('./img/hero.png'),
  brandLogo: require('./icons/99Starz.svg').default,
  google: require('./icons/google.svg').default,
  walllet: require('./icons/wallet.svg').default,
  notification: require('./icons/notificationIcon.svg').default,
  user: require('./img/user.png'),
  dashboard: require('./img/dashboard.png'),
  menuIcon: require('./icons/menuIcon.svg').default,
  home: require('./icons/home.svg').default,
  earnings: require('./icons/earnings.svg').default,
  copyIcon: require('./icons/copy.svg').default,
  deltaTime: require('./icons/DeltaTime.svg').default,
  sorare: require('./icons/Sorare.svg').default,
  axie: require('./icons/axie.svg').default,
  revvRacing: require('./icons/Revv-Racing.svg').default,
  cryptoMotors: require('./icons/Cryptomotors.svg').default,
  phantom: require('./icons/phantom.svg').default,
  arena: require('./icons/arena.svg').default,
  goldBadge: require('./icons/goldBadge.svg').default,
  silverBadge: require('./icons/silverBadge.svg').default,
  bronzeBadge: require('./icons/bronzeBadge.svg').default,
  stzIcon: require('./icons/stzIcon.svg').default,
  nft: require('./img/nft.png'),
  SLP: require('./icons/SLP.svg').default,
  riot: require('./icons/Riot.svg').default,
  downArrow: require('./icons/downArrow.svg').default,
  sorareMini: require('./icons/SorareMini.svg').default,
  axieMini: require('./icons/axieMini.svg').default,
  cryptoMini: require('./icons/cryptoMini.svg').default,
  transfer: require('./icons/transfer.svg').default,
  closeIcon: require('./icons/close.svg').default,
  moreVert: require('./icons/moreVert.svg').default,
  performance: require('./icons/performance.svg').default,
  voting: require('./icons/voting.svg').default,
  helpSupport: require('./icons/helpSupport.svg').default,
  msg: require('./icons/msg.svg').default,
  asset: require('./icons/asset.svg').default,
  ETH: require('./icons/ETH.svg').default,
  cloudUp: require('./icons/cloudUp.svg').default,
  quit: require('./icons/quit.svg').default,
  gamesIcon: require('./icons/gamesIcon.svg').default,
  axieBanner: require('./img/axie-banner.png'),
  arenaBanner: require('./img/arena-banner.png'),
  benjiBanana: require('./img/benjiBanana.png'),
  sliderBg: require('./img/sliderBg.png'),
  discord: require('./icons/discord.svg').default,
  telegram: require('./icons/telegram.svg').default,
  wallpaper: require('./img/wallpaper.png'),
  vault: require('./icons/vault.svg').default,
  searchIcon: require('./icons/search.svg').default,
  riotNft: require('./img/riotNft.png'),
  THC: require('./icons/THC.svg').default,
  arrowLeft: require('./icons/arrowLeft.svg').default,
  arrowRight: require('./icons/arrowRight.svg').default,
  sliderImg: require('./img/slider-image.png'),
  metamask: require('./icons/Metamask.svg').default,
  glitch: require('./img/glitch.png'),
  riotRacers: require('./icons/riotRacers.svg').default,
  logout: require('./img/logout.png'),
  show: require('./img/show.png'),
  hide: require('./img/hide.png'),
  blank: require('./img/blank.png'),
  primate: require('./icons/primate.png'),
};

export default assets;
