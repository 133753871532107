import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { getNotifications } from '../../http';
import { INotification } from '../../types/interfaces';

interface IState {
  loading: boolean;
  notifications: INotification[];
}

export const notifications = createModel<RootModel>()({
  name: 'notifications',
  state: {
    loading: false,
    notifications: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setNotifications(state, payload: INotification[]) {
      state.notifications = payload;
    },
  },
  effects: dispatch => ({
    async getAllNotifications(_, state) {
      try {
        dispatch.notifications.setLoading(true);
        const id = state.auth.user?.id;
        if (id) {
          const { data } = await getNotifications(id);
          dispatch.notifications.setNotifications(data);
        }
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.notifications.setLoading(false);
      }
    },
  }),
});
