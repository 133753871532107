import React, {
  FC,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';

import styled from 'styled-components';
import classNames from 'classnames';

import assets from '../../assets';

interface IProps {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  children: ReactNode;
}

const Filters: FC<IProps> = ({ children, search, setSearch }) => {
  const [filterOpen, setFilterOpen] = useState(false);
  return (
    <Container>
      <FilterHeader>
        <button
          className='filter-button'
          onClick={() => setFilterOpen(!filterOpen)}
        >
          <p className='font-size-12'>Filters</p>
          <img src={assets.downArrow} alt='' />
        </button>
        <div className='search'>
          <label htmlFor='search'>
            <img src={assets.searchIcon} alt='' />
          </label>
          <input
            type='text'
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder='Search'
            id='search'
          />
        </div>
      </FilterHeader>
      <Collapse className={classNames(filterOpen && 'opened')}>
        <div className='grid'>{children}</div>
      </Collapse>
    </Container>
  );
};

export default Filters;

const Container = styled.div`
  margin-top: 30px;
`;

const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & button {
    border: none;
    background: #121212;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    padding: 0px 12px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    cursor: pointer;
  }

  & .search {
    background: #121212;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    padding: 0px 12px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 288px;
    gap: 10px;

    & input {
      outline: none;
      border: none;
      height: 38px;
      flex-grow: 1;
      background-color: transparent;
      color: #ffffff;
    }
  }

  @media only screen and (max-width: 700px) {
    gap: 20px;
    & .search {
      flex-grow: 1;
      max-width: 200px;
    }
  }
`;

const Collapse = styled.div`
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.4s ease;
  &.opened {
    max-height: 130px;
  }
  & .grid {
    margin-top: 42px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
    border-bottom: 1px solid #ffffff1a;
    padding-bottom: 24px;
  }

  @media only screen and (max-width: 1100px) {
    &.opened {
      max-height: 200px;
    }
    & .grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media only screen and (max-width: 700px) {
    &.opened {
      max-height: 200px;
    }
    & .grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media only screen and (max-width: 480px) {
    &.opened {
      max-height: 400px;
    }
    & .grid {
      grid-template-columns: 1fr;
    }
  }
`;
