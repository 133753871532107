import React, { FC } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import theme from '../../theme';

interface IProps {
  variant: 'primary' | 'secondary' | 'normal' | 'transparent';
  [x: string]: any;
}

const Button: FC<IProps> = ({ children, variant, className, ...rest }) => {
  return (
    <StyledButton className={classNames(variant, className)} {...rest}>
      {children}
      <span>{children}</span>
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button`
  & span {
    font-family: 'Krona One', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #ffffff;
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  font-family: 'Krona One', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: transparent;
  padding: 15px 30px;

  cursor: pointer;
  &.primary {
    clip-path: polygon(
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%,
      0 0
    );
    background-color: ${theme.palette.primary};
    border: none;
    text-transform: capitalize;
    position: relative;
    overflow: hidden;
    &:hover {
      color: transparent;
      background-color: ${theme.palette.primaryDark};
    }
    &:disabled {
      background-color: ${theme.palette.primaryDark + 33};
    }
    &:disabled:hover {
      cursor: not-allowed;
      background-color: ${theme.palette.primaryDark + 33};
    }
    &:disabled:hover::after {
      width: 2px;
    }
    &::after {
      content: '';
      height: 100%;
      width: 2px;
      background-color: ${theme.palette.secondary};
      position: absolute;
      left: 0;
      top: 0;
      transition: 0.3s ease;
    }
    &:hover::after {
      width: 100%;
    }
  }
  &.transparent {
    clip-path: polygon(
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%,
      0 0
    );
    background-color: transparent;
    border: none;
    text-transform: capitalize;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 0px 2px #ffffff33 inset;
    &:hover {
      color: transparent;
      background-color: transparent;
    }
    &:disabled {
      background-color: ${theme.palette.primaryDark + 33};
    }
    &:disabled:hover {
      cursor: not-allowed;
      background-color: ${theme.palette.primaryDark + 33};
    }
    &:disabled:hover::after {
      width: 2px;
    }
    &::after {
      content: '';
      height: 100%;
      width: 2px;
      background-color: ${theme.palette.primary};
      position: absolute;
      left: 0;
      top: 0;
      transition: 0.3s ease;
    }
    &:hover::after {
      width: 100%;
    }
    &::before {
      content: '';
      height: 15px;
      width: 15px;
      background-color: ${theme.palette.black};
      position: absolute;
      right: -8px;
      bottom: -8px;
      transform: rotate(45deg);
      border-left: 2.5px solid #ffffff33;
      z-index: -1;
    }
  }
  &.secondary {
    box-sizing: border-box;
    background-color: transparent;
    box-shadow: 0px 0px 0px 2px #ffffff33 inset;
    border: none;
    text-transform: capitalize;
    position: relative;
    overflow: hidden;
    /* background-color: ${theme.palette.secondary}; */
    clip-path: polygon(0 0, 0 calc(100% - 10px), 10px 100%, 100% 100%, 100% 0);
    &:disabled {
      background-color: #ffffff1a;
      box-shadow: 0px 0px 0px 2px transparent inset;
    }
    &:disabled:hover {
      cursor: not-allowed;
      background-color: #ffffff1a;
    }
    &:disabled:hover::after {
      width: 2px;
    }
    &:disabled::before {
      border: 1px solid transparent;
      height: 0px;
      width: 0px;
    }
    &:hover {
      color: transparent;
      background-color: rgba($color: ${theme.palette.primaryDark}, $alpha: 0.2);
    }
    &:disabled {
    }
    &::before {
      content: '';
      height: 15px;
      width: 15px;
      background-color: ${theme.palette.black};
      position: absolute;
      left: -8px;
      bottom: -8px;
      transform: rotate(45deg);
      border-top: 2.5px solid #ffffff33;
      z-index: -1;
    }
    &::after {
      content: '';
      height: 100%;
      width: 2px;
      background-color: ${theme.palette.primary};
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.3s ease;
    }
    &:hover::after {
      width: 100%;
    }
  }
  &.normal {
    box-sizing: border-box;
    background-color: transparent;
    box-shadow: 0px 0px 0px 2px #ffffff33 inset;
    border: none;
    text-transform: capitalize;
    position: relative;
    overflow: hidden;
    &:hover {
      color: transparent;
    }
    &::before {
      content: '';
      height: 15px;
      width: 15px;
      background-color: ${theme.palette.black};
      position: absolute;
      right: -9px;
      bottom: -9px;
      transform: rotate(45deg);
      border-left: 2px solid #ffffff33;
    }
  }
`;
