import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

import DialogLayout from './Dialog';
import Button from '../components/Button/Button';
import assets from '../assets';

interface IProps {
  open: boolean;
  setETH: React.Dispatch<React.SetStateAction<boolean>>;
  setSTZ: React.Dispatch<React.SetStateAction<boolean>>;
  showEth: boolean;
  showStz: boolean;
}

const EthAndStz: FC<IProps> = ({ open, setETH, setSTZ, showStz, showEth }) => {
  const walletAccounts = useSelector(
    (state: RootState) => state.auth.walletAccounts
  );
  return (
    <DialogLayout open={open}>
      <Container>
        {showEth && (
          <Dialog>
            <div className='close-icon' onClick={() => setETH(false)}>
              <img src={assets.closeIcon} alt='' />
            </div>
            <div className='content'>
              <h1 className='title'>Send ETH</h1>
              <p className='font-size-14'>send 0.1 ETH to :</p>
              <p className='font-size-14 mt'>
                {walletAccounts[0]?.starz_wallet_address}
              </p>
              <div className='btn'>
                <Button
                  variant='primary'
                  // onClick={() => dispatch.dialogs.closeDialog('transfer')}
                >
                  Buy ETH
                </Button>
                <Button
                  variant='primary'
                  // onClick={() => dispatch.dialogs.closeDialog('transfer')}
                >
                  Send
                </Button>
              </div>
            </div>
          </Dialog>
        )}

        {showStz && (
          <Dialog className='second'>
            <div className='close-icon' onClick={() => setSTZ(false)}>
              <img src={assets.closeIcon} alt='' />
            </div>
            <div className='content'>
              <h1 className='title'>Send STZ</h1>
              <p className='font-size-14'>send STZ to :</p>
              <p className='font-size-14 mt'>
                {walletAccounts[0]?.starz_wallet_address}
              </p>
              <div className='btn'>
                <Button
                  variant='primary'
                  // onClick={() => dispatch.dialogs.closeDialog('transfer')}
                >
                  Buy STZ
                </Button>
                <Button
                  variant='primary'
                  // onClick={() => dispatch.dialogs.closeDialog('transfer')}
                >
                  Send
                </Button>
              </div>
            </div>
          </Dialog>
        )}
      </Container>
    </DialogLayout>
  );
};

export default EthAndStz;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  padding: 20px;
`;

const Dialog = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: 420px; */
  padding-inline: 25px;
  &.second {
    /* margin-top: 40px; */
  }
  & .close-icon {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    & img {
      cursor: pointer;
    }
  }
  & .content {
    margin-top: 6px;
    padding: 40px 30px;
    background-color: #121212;
    @media only screen and (max-width: 400px) {
      padding: 30px 10px;
    }

    & p {
      margin-block: 20px 0px;
    }
    & p.mt {
      margin-block: 20px 40px;
    }

    & .btn {
      display: flex;
      justify-content: center;
      grid-gap: 14px;
    }
  }
`;
