import React, { FC, useEffect, useState } from 'react';

import { ethers } from 'ethers';
import styled from 'styled-components';
import assets from '../assets';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../store';

import DialogLayout from './Dialog';
import InputField from '../components/InputField/InputField';
import Button from '../components/Button/Button';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const WalletConnections: FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();

  const walletAddress = useSelector(
    (state: RootState) => state.auth?.walletAccounts[0]?.wallet_address
  );

  const loading = useSelector((state: RootState) => state.auth.loading);

  const [address, setAddress] = useState(walletAddress || '');

  const handleUpdateAddress = () => {
    if (ethers.utils.isAddress(address)) {
      dispatch.auth.updatePayoutWalletAddress({
        data: {
          public_address: walletAddress,
          new_public_address: address,
        },
        setOpen,
      });
    }
  };
  useEffect(() => {
    if (walletAddress) {
      setAddress(walletAddress);
    }
  }, [walletAddress]);
  return (
    <DialogLayout open={open}>
      <Container>
        <div className='close-btn-container'>
          <img
            src={assets.closeIcon}
            onClick={() => setOpen(false)}
            style={{ cursor: 'pointer' }}
            alt=''
          />
        </div>
        <Content>
          <h1 className='title'>Wallet connection successfully!</h1>
          <p className='font-size-14'>
            Your connected wallet will be used for checking NFTs and STZ as per
            requirement and payout as well.
          </p>
          <p className='font-size-14' style={{ marginBlock: '20px 30px' }}>
            Please update the wallet from the button below or from the profile
            tab or send the assets to this one to make sure the requirements are
            met.
          </p>

          <InputField
            placeholder='Wallet Address'
            value={address}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setAddress(e.currentTarget.value)
            }
            type='text'
          />
          <ButtonContainer>
            <Button
              variant='secondary'
              onClick={() => setOpen(false)}
              disabled={loading}
            >
              Continue
            </Button>
            <Button
              variant='primary'
              onClick={handleUpdateAddress}
              disabled={loading}
            >
              Update wallet
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default WalletConnections;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 392px;

  & .close-btn-container {
    display: flex;
    justify-content: flex-end;
    padding: 6px;
  }
`;

const Content = styled.div`
  background: #121212;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
  padding: 40px 30px;

  & p {
    margin-block: 24px 40px;
  }

  @media only screen and (max-width: 420px) {
    padding: 20px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  & button {
    height: 48px;
  }
`;
