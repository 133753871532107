import React, { FC } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import assets from '../../assets';
// import theme from '../../theme';
import { IFilterGame } from '../../types/interfaces';

interface IProps {
  game?: IFilterGame;
}

const Game: FC<IProps> = ({ game }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/dashboard/games/${game?.id}`);
  };

  return (
    <GameCard>
      <img
        src={game?.banner || assets.benjiBanana}
        className='banner'
        alt=''
        onClick={handleNavigate}
      />
      <div className='row'>
        <p className='font-size-14'>{game?.name || 'Similar Game'}</p>
        <img src={assets.moreVert} className='menu-icon' alt='' />
      </div>
      {/* <SpotCount
        style={{
          backgroundColor:
            spots > 3 ? theme.palette.primary : theme.palette.secondary,
        }}
      >
        <p className='font-size-14' style={{ fontFamily: 'Stalinist One' }}>
          {spots}
        </p>
        <p className='font-size-8'>
          {spots > 3
            ? 'Spots left'
            : spots === 1
            ? 'Spot left!'
            : 'Spots left!'}
        </p>
      </SpotCount> */}
    </GameCard>
  );
};

export default Game;

const GameCard = styled.div`
  width: 100%;
  position: relative;
  padding-top: 8px;
  cursor: pointer;
  & img.banner {
    aspect-ratio: 16/9;
    width: 100%;
  }

  & .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    padding-inline: 10px;
    & .menu-icon {
      opacity: 0.4;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
  transition: 0.6s ease;
`;

// const SpotCount = styled.div`
//   position: absolute;
//   top: 0px;
//   right: 8px;
//   padding: 10px 0px;
//   min-width: 80px;
//   height: 48px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   @media only screen and (max-width: 420px) {
//     height: 35px;
//     min-width: 60px;
//     & p.font-size-14 {
//       font-size: 10px;
//       line-height: 14px;
//     }
//   }
// `;
