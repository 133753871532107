import React, { FC } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../store';

import DialogLayout from './Dialog';
import Button from '../components/Button/Button';

import assets from '../assets';

const FeedBack: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const open = useSelector((state: RootState) => state.feedbackModal.open);
  const title = useSelector((state: RootState) => state.feedbackModal.title);
  const subtitle = useSelector(
    (state: RootState) => state.feedbackModal.subtitle
  );
  return (
    <DialogLayout open={open}>
      <Container>
        <div className='close-btn'>
          <img
            src={assets.closeIcon}
            alt=''
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch.feedbackModal.close()}
          />
        </div>
        <Content>
          <h1 className='title'>{title}</h1>
          <p className='font-size-14'>{subtitle}</p>
          <div className='text-center'>
            <Button
              variant='primary'
              onClick={() => dispatch.feedbackModal.close()}
            >
              Continue
            </Button>
          </div>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default FeedBack;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 392px;

  & .close-btn {
    display: flex;
    justify-content: flex-end;
    padding-block: 6px;
  }
`;

const Content = styled.div`
  background: #121212;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
  padding: 40px 30px;

  & p {
    margin-block: 24px 40px;
  }

  @media only screen and (max-width: 420px) {
    padding: 20px;
  }
`;
