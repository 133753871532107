import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
// import ScholarCard from '../../components/ScholarCard/ScholarCard';
import GameSlider from '../../components/GameSlider/GameSlider';
import ApplyScholarshipDialog from '../../dialogs/ApplyScholarshipDialog';

import assets from '../../assets';

const Games: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const [apply, setApply] = useState(false);
  // eslint-disable-next-line
  const scholarships = useSelector(
    (state: RootState) => state.scholarships.scholarships
  );

  const goldBadgeGames = useSelector(
    (state: RootState) => state.games.goldBadgeGames
  );
  const silverBadgeGames = useSelector(
    (state: RootState) => state.games.silverBadgeGames
  );
  const bronzeBadgeGames = useSelector(
    (state: RootState) => state.games.bronzeBadgeGames
  );

  // const newGames = useSelector((state: RootState) => state.games.newGames);

  useEffect(() => {
    dispatch.scholarships.getAllScholarships();
    dispatch.games.getAllGamesCategory();
  }, []);
  return (
    <Container>
      <Padding>
        {/* <h1 className="title">Games Looking for Scholars!</h1> */}
        {/* <ScholarCardsContainer>
          <div className='wrapper'>
            {scholarships.map(scholarship => (
              <ScholarCard
                key={scholarship.scholarship_id}
                scholarshipData={scholarship}
                setApply={setApply}
              />
            ))}
          </div>
        </ScholarCardsContainer> */}

        {/* Free to play games */}
        {/* <h2 className='font-size-20 title'>Free to Play!</h2>
        <SliderContainer>
          <GameSlider games={[]} />
        </SliderContainer> */}
      </Padding>

      {/* New Games */}
      {/* {newGames.length && (
        <SliderBackground
          style={{ backgroundImage: `url(${assets.sliderBg})` }}
        >
          <h2 className='font-size-20 title'>New Games</h2>
          <SliderContainer>
            <GameSlider games={newGames} />
          </SliderContainer>
        </SliderBackground>
      )} */}

      <Padding>
        {/* Gold badge games */}

        {goldBadgeGames.length > 0 && (
          <BadgeSlider>
            <div className='row title'>
              <img src={assets.goldBadge} alt='' />
              <p className='font-size-20 title'>
                Mint gold badge and apply for scholarship
              </p>
            </div>
            <GameSlider games={goldBadgeGames} />
          </BadgeSlider>
        )}

        {/* Silver badge games */}
        {silverBadgeGames.length > 0 && (
          <BadgeSlider>
            <div className='row title'>
              <img src={assets.silverBadge} alt='' />
              <p className='font-size-20 title'>
                Mint silver badge and apply for scholarship
              </p>
            </div>
            <GameSlider games={silverBadgeGames} />
          </BadgeSlider>
        )}

        {/* Bronze badge games */}
        {bronzeBadgeGames.length > 0 && (
          <BadgeSlider>
            <div className='row title'>
              <img src={assets.bronzeBadge} alt='' />
              <p className='font-size-20 title'>
                Mint bronze badge and apply for scholarship
              </p>
            </div>
            <GameSlider games={bronzeBadgeGames} />
          </BadgeSlider>
        )}
      </Padding>

      {apply && <ApplyScholarshipDialog open={apply} setOpen={setApply} />}
    </Container>
  );
};

export default Games;

const Container = styled.div`
  flex-grow: 1;
  padding-top: 40px;
  & .title {
    margin-bottom: 30px;
  }

  & h2.font-size-20 {
    margin-block: 60px 24px;
  }
`;

const Padding = styled.div`
  padding-inline: 56px;
  /* padding-top: 72px; */

  &:nth-last-of-type(2) {
    padding-top: 0px;
  }

  @media only screen and (max-width: 700px) {
    padding-inline: 25px;
  }
`;
// eslint-disable-next-line
const ScholarCardsContainer = styled.div`
  & .wrapper {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); */
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
  }

  @media only screen and (max-width: 1000px) {
    & .wrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media only screen and (max-width: 800px) {
    & .wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media only screen and (max-width: 420px) {
    & .wrapper {
      grid-template-columns: 1fr 1fr;
      gap: 20px 16px;
    }
  }
`;
// eslint-disable-next-line
const SliderContainer = styled.div``;
// eslint-disable-next-line
const SliderBackground = styled.div`
  background-size: cover;
  background-position: center;
  padding-block: 40px 60px;
  margin-block: 60px;
  padding-inline: 56px;

  & h2.font-size-20 {
    margin-block: 0px 24px;
  }

  @media only screen and (max-width: 700px) {
    padding-inline: 25px;
  }
`;

const BadgeSlider = styled.div`
  margin-block: 60px;

  & div.title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    & p.title {
      margin: 0px;
    }

    & img {
      width: 36px;
    }
  }
`;
