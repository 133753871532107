import { Models } from '@rematch/core';
import { sideBar } from './sideBar';
import { dialogs } from './dialogs';
import { auth } from './auth';
import { games } from './games';
import { badges } from './badge';
import { errorModal } from './errorDialog';
import { nfts } from './nfts';
import { vault } from './vault';
import { scholarships } from './scholarships';
import { feedbackModal } from './feedbackModal';
import { notifications } from './notifications';

export interface RootModel extends Models<RootModel> {
  sideBar: typeof sideBar;
  dialogs: typeof dialogs;
  auth: typeof auth;
  games: typeof games;
  badges: typeof badges;
  errorModal: typeof errorModal;
  nfts: typeof nfts;
  vault: typeof vault;
  scholarships: typeof scholarships;
  feedbackModal: typeof feedbackModal;
  notifications: typeof notifications;
}

export const models: RootModel = {
  sideBar,
  dialogs,
  auth,
  games,
  badges,
  errorModal,
  nfts,
  vault,
  scholarships,
  feedbackModal,
  notifications,
};
