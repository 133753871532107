import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Navigate, useNavigate } from "react-router-dom";

import Page from "../../layouts/Page";
import Button from "../../components/Button/Button";
import NftImage from "../../components/NftImage/NftImage";

import assets from "../../assets";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { getStzBalance } from "../../utils";

const MyBadge: FC = () => {
  const navigate = useNavigate();

  const [stzBalance, setStzBalance] = useState(0);

  const walletAccounts = useSelector(
    (state: RootState) => state.auth.walletAccounts
  );
  const selectedBadge = useSelector(
    (state: RootState) => state.badges.selected
  );
  const starz_wallet_address = walletAccounts[0]?.starz_wallet_address;

  const nfts = useSelector((state: RootState) => state.nfts.nfts);

  useEffect(() => {
    (async () => {
      const balance = await getStzBalance(starz_wallet_address);
      setStzBalance(+balance);
    })();
  }, []);

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(walletAccounts[0]?.starz_wallet_address);
    alert("Address copied.");
  };

  if (!selectedBadge) {
    return <Navigate to="/dashboard/select-game" replace={true} />;
  }

  return (
    <Page>
      <Container>
        <div>
          <img src={selectedBadge.logo} alt="" />
        </div>
        <h1 className="title text-center">
          Your {selectedBadge.name} is here!
        </h1>
        <div className="nft-details">
          <div className="row align-center">
            <p className="font-size-12">99Starz Scholar Wallet Address</p>
            {/* <div className='address'> */}
            <div className="break-word">
              <span className="font-size-14 unselectable">
                {!!walletAccounts.length &&
                  walletAccounts[0].starz_wallet_address}
              </span>
              <img
                src={assets.copyIcon}
                className="copy-icon"
                onClick={handleCopyAddress}
                alt=""
              />
            </div>
            {/* </div> */}
          </div>
          <div className="row align-center">
            <p className="font-size-12">Wallet Balance</p>
            <p className="font-size-14">{stzBalance} STZ</p>
          </div>
          <div className="row align-start">
            <p className="font-size-12">Wallet NFTs</p>
            <div className="row flex-wrap">
              {nfts.map((nft, index) => (
                <NftImage nftData={nft} size={60} key={index} />
              ))}
            </div>
          </div>

          {/* <div className='row align-center'>
            <p className='font-size-12'>Game ID</p>
            <p className='font-size-14'>AlfaJunkie</p>
          </div> */}
        </div>
        <div className="btn-container">
          <Button
            variant="secondary"
            onClick={() => navigate("/dashboard/assets")}
          >
            Download 99Starz wallet
          </Button>
          <Button
            variant="primary"
            onClick={() => navigate("/dashboard/assets")}
          >
            My Assets
          </Button>
        </div>
        <div className="margin-bottom"></div>
      </Container>
    </Page>
  );
};

export default MyBadge;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & h1 {
    margin-top: 40px;
  }
  & .nft-details {
    background-color: #121212;
    width: 640px;
    margin-block: 30px 40px;
    padding: 25px 30px;
    display: flex;
    flex-direction: column;
    & .nft {
      height: 60px;
      width: 60px;
      border-radius: 6px;
      background-color: #ffffff;
      display: grid;
      place-items: center;
    }

    & .row {
      padding-block: 14px;
      padding-inline: 10px;
      border-bottom: 1px solid #ffffff1a;
    }
    & .row:first-child {
      padding-block: 0 14px;
    }
    & .row:last-child {
      border: none;
    }
  }

  & .row {
    display: flex;
    grid-gap: 20px;
  }

  & .row.align-center {
    align-items: center;
  }

  & .row.align-start {
    align-items: flex-start;
  }
  & .row.flex-wrap {
    flex-wrap: wrap;
    grid-gap: 10px;
    padding: 0;
    border-bottom: none;
  }
  & .margin-bottom {
    height: 60px;
  }
  & p.font-size-12 {
    min-width: 34%;
    opacity: 0.4;
  }

  & .break-word {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    & .copy-icon {
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      width: 12px;
    }

    & span.font-sizw-14 {
      display: table-cell;
      vertical-align: middle;
      overflow: hidden;
      word-wrap: break-word;
    }
  }

  & .address {
    display: flex;
    grid-gap: 12px;
  }

  & .btn-container {
    display: flex;
    align-items: center;
    grid-gap: 21px;
    & .download-wallet {
      padding: 5px 15px;
    }
  }

  @media only screen and (max-width: 1150px) {
    & .nft-details {
      width: 70%;
      & .nft {
        height: 46px;
        width: 46px;
        & img {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    & .nft-details {
      width: 80%;
      padding: 20px 30px;
      & .row {
        flex-direction: column;
        padding-inline: 0;
      }
    }

    & .row.flex-wrap {
      flex-direction: row;
      grid-gap: 8px;
    }

    & .font-size-12 {
      min-width: 100%;
    }

    & .row.align-center {
      align-items: flex-start;
    }
    & .break-word {
      width: 80%;
    }
  }
  @media only screen and (max-width: 700px) {
    & .nft-details {
      width: 100%;
    }
    & .margin-bottom {
      height: 120px;
    }
    & .break-word {
      width: 100%;
    }
    & .btn-container {
      display: flex;
      align-items: center;
      grid-gap: 14px;
    }
  }
`;
