import React, { ChangeEvent, FC, useState } from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { InferType, object, string, ref } from "yup";
import { FormErrors, validateData } from "../../utils/validations";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../store";

import LoginLayout from "../../layouts/LoginLayout";
import InputField from "../../components/InputField/InputField";
import Checkbox from "../../components/Checkbox/Checkbox";
import Button from "../../components/Button/Button";

import theme from "../../theme";

const SignUp: FC = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const dispatch = useDispatch<Dispatch>();

  const loading = useSelector((state: RootState) => state.auth.loading);

  const signupSchema = object({
    // fullname: string().required().label('Full name'),
    email: string().email().required().label("Email"),
    // phone_number: string()
    //   .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g)
    //   .label('Phone number'),
    password: string().min(6).required("Password"),
    confirm_password: string()
      .oneOf(
        [ref("password"), null],
        "Repeat password must be same as password"
      )
      .required()
      .label("Repeat password"),
  });

  interface SignupFormData extends InferType<typeof signupSchema> {}

  const [errors, setErrors] = useState<FormErrors<SignupFormData>>({
    // fullname: '',
    email: "",
    // phone_number: '',
    password: "",
    confirm_password: "",
  });

  const [formData, setFormData] = useState<SignupFormData>({
    // fullname: '',
    email: "",
    // phone_number: '',
    password: "",
    confirm_password: "",
  });

  const [policyCheck, setPolicyCheck] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((fd) => ({
      ...fd,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setErrors({
      // fullname: '',
      email: "",
      // phone_number: '',
      password: "",
      confirm_password: "",
    });
    const { data, errors: validationErrors } =
      await validateData<SignupFormData>(signupSchema, formData);

    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }
    // navigate('/account-confirmation');
    dispatch.auth.register({ data, setErrors, errors, navigate });
  };

  return (
    <LoginLayout>
      <Title>
        <h1>
          Create 99Starz <br /> account
        </h1>
      </Title>
      <Form onSubmit={(e) => e.preventDefault()}>
        {/* <FieldContainer>
          <InputField
            placeholder='Full Name'
            type='text'
            name='fullname'
            value={formData.fullname}
            onChange={handleChange}
            error={errors.fullname}
          />
        </FieldContainer> */}
        <FieldContainer>
          <InputField
            placeholder="Email adress"
            type="email"
            name="email"
            onChange={handleChange}
            error={errors.email}
            value={formData.email}
          />
        </FieldContainer>
        {/* <FieldContainer>
          <InputField
            placeholder='Phone number'
            type='number'
            name='phone_number'
            onChange={handleChange}
            error={errors.phone_number}
            value={formData.phone_number}
          />
        </FieldContainer> */}
        <FieldContainer>
          <InputField
            placeholder="Password"
            type="password"
            name="password"
            onChange={handleChange}
            error={errors.password}
            value={formData.password}
            hidden
          />
        </FieldContainer>
        <FieldContainer>
          <InputField
            placeholder="Repeat password"
            type="password"
            name="confirm_password"
            onChange={handleChange}
            error={errors.confirm_password}
            value={formData.confirm_password}
            hidden
          />
        </FieldContainer>
        {/* <FieldContainer>
          <InputField placeholder='Refferal code (optional)' type='password' />
        </FieldContainer> */}

        <PrivacyPolicy>
          <Checkbox
            className="checkbox"
            checked={policyCheck}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPolicyCheck(e.target.checked)
            }
          />
          <p className="font-size-12">
            By creating an account you agree to the
            <Link className="anchor" to="#">
              &nbsp;terms of use &nbsp;
            </Link>
            and our
            <Link className="anchor" to="#">
              &nbsp;privacy policy&nbsp;
            </Link>
            .
          </p>
        </PrivacyPolicy>
        <Button
          variant="primary"
          className="continue-button"
          // onClick={() => navigate('/account-confirmation')}
          onClick={handleSubmit}
          disabled={loading || !policyCheck}
        >
          Continue
        </Button>

        {/* <div className='divider'>
          <span>Or</span>
        </div>

        <div className='other-login'>
          <Button variant='normal' className='btn btn-google'>
            <img src={assets.google} alt='' />
          </Button>
          <Button variant='normal' className='btn btn-otp'>
            OTP
          </Button>
        </div> */}

        <div className="have-account">
          <p className="font-size-14">
            Already have an account?{" "}
            <Link className="anchor" to="/">
              Log in
            </Link>
          </p>
        </div>
      </Form>
    </LoginLayout>
  );
};

export default SignUp;

const Form = styled.form`
  & .continue-button {
    margin-block: 40px;
    width: 100%;
  }

  & a {
    color: ${theme.palette.anchor};
    &.anchor-secondary {
      color: ${theme.palette.secondary};
    }
  }

  & .divider {
    height: 1px;
    background-color: #ffffff33;
    position: relative;
    & span {
      color: #ffffff33;
      font-size: 12px;
      line-height: 18px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -45%);
      top: 0;
      background-color: ${theme.palette.black};
      width: 48px;
      text-align: center;
    }
  }
  & .other-login {
    margin-block: 45px 20px;
    display: flex;
    justify-content: center;
    & .btn {
      width: 90px;
    }
    & .btn-google {
      margin-right: 10px;
    }
    & .btn-otp {
      margin-left: 10px;
    }
  }
  & .have-account {
    text-align: center;
  }
`;

const Title = styled.div`
  margin-bottom: 40px;
  /* margin-top: 80px; */
  & h1 {
    font-family: "Krona One", sans-serif;
  }
`;

const FieldContainer = styled.div`
  margin-bottom: 34px;
  width: 100%;
  & input[type="number"]::-webkit-inner-spin-button {
    display: none;
    margin: 0;
  }
  & input[type="number"]::-webkit-outer-spin-button {
    display: none;
    margin: 0;
  }
`;

const PrivacyPolicy = styled.div`
  display: flex;
  justify-content: space-between;
  & .checkbox {
    margin-top: 4px;
    margin-right: 8px;
  }
`;
