import React, { FC } from 'react';
import ExchangeDialog from './ExchangeDialog';
import TransferDialog from './TransferDialog';
import ErrorDialog from './ErrorDialog';
import FeedBack from './FeedBack';

const Dialogs: FC = () => {
  return (
    <>
      <ExchangeDialog />
      <TransferDialog />
      <ErrorDialog />
      <FeedBack />
    </>
  );
};

export default Dialogs;
