import React, { FC } from 'react';
import styled from 'styled-components';
import assets from '../assets';

const LoginLayout: FC = ({ children }) => {
  return (
    <Container>
      <ImageContainer className='left-container'></ImageContainer>
      <FormContainer className='right-container'>
        <LogoContainer>
          <img src={assets.brandLogo} alt='' />
        </LogoContainer>
        {children}
      </FormContainer>
    </Container>
  );
};

export default LoginLayout;

const Container = styled.div`
  height: 100vh;
  display: flex;
`;

const ImageContainer = styled.div`
  background-image: url(${assets.hero});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-grow: 1;
`;

const FormContainer = styled.div`
  width: 600px;
  padding-inline: 110px;
  padding-block: 80px 60px;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 1200px) {
    width: 500px;
    padding-inline: 70px;
    padding-block: 60px 40px;
  }
  @media only screen and (max-width: 900px) {
    width: 450px;
    padding-inline: 50px;
    padding-block: 50px 30px;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
    padding-inline: 50px;
    padding-block: 50px 30px;
  }
  @media only screen and (max-width: 400px) {
    width: 100%;
    padding-inline: 30px;
    padding-block: 50px 30px;
  }
`;

const LogoContainer = styled.div`
  text-align: center;
  margin-bottom: 40px;
  display: none;
  @media only screen and (max-width: 700px) {
    display: block;
  }
`;

// const Form = styled.form``;
