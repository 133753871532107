import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { getBadges, getUserMintedBadges } from '../../http';
import { IBadgeModal, IBadge, IMintedBadge } from '../../types/interfaces';

export const badges = createModel<RootModel>()({
  name: 'badges',
  state: {
    selected: null,
    badges: [],
    loading: false,
    error: '',
    mintedBadges: [],
  } as IBadgeModal,
  reducers: {
    setBadges(state, payload: Array<IBadge>) {
      state.badges = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setError(state, payload: string) {
      state.error = payload;
    },
    setSelectedBadge(state, paylaod: IBadge) {
      state.selected = paylaod;
    },
    setMintedBadges(state, payload: Array<IMintedBadge>) {
      state.mintedBadges = payload;
    },
  },
  effects: dispatch => ({
    async getAllBadges() {
      try {
        dispatch.badges.setLoading(true);
        const { data } = await getBadges();
        dispatch.badges.setBadges(data);
      } catch (err) {
        dispatch.badges.setError('Something went wrong');
      } finally {
        dispatch.badges.setLoading(false);
      }
    },
    async getMintedBadges(payload: string) {
      try {
        dispatch.badges.setLoading(true);
        const { data } = await getUserMintedBadges(payload);
        dispatch.badges.setMintedBadges(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.badges.setLoading(false);
      }
    },
  }),
});
