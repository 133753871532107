import React, { FC, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';

import Page from '../../layouts/Page';
import Button from '../../components/Button/Button';
import Chart from '../../components/Chart/Chart';
import Loader from '../../components/Loader/Loader';
import { getEthBalance, getStzBalance } from '../../utils';

import assets from '../../assets';

const MyEarnings: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector((state: RootState) => state.auth.user);
  const loading = useSelector((state: RootState) => state.scholarships.loading);
  const [balances, setBalances] = useState({ eth: 0, stz: 0 });
  const starz_wallet_address = useSelector(
    (state: RootState) => state.auth.walletAccounts[0].starz_wallet_address
  );
  const walletAddress = useSelector(
    (state: RootState) => state.auth.walletAccounts[0].wallet_address
  );
  const tokenBalances = useSelector(
    (state: RootState) => state.games.tokenBalances
  );
  const scholarGames = useSelector(
    (state: RootState) => state.games.scholarGames
  );

  const tableMenus = useRef<any>([]);

  const addToRef = (e: any) => {
    if (e && !tableMenus.current.includes(e)) tableMenus.current.push(e);
  };

  const handleMenuClick = (index: number) => {
    const menu: HTMLDivElement = tableMenus.current[index];
    if (menu) {
      if (menu.classList.contains('show')) {
        menu.classList.remove('show');
        return;
      }
      tableMenus.current.forEach((e: HTMLDivElement) =>
        e.classList.remove('show')
      );
      menu.classList.add('show');
    }
  };

  const handleCloseMenu = (index: number) => {
    const menu: HTMLDivElement = tableMenus.current[index];
    menu.classList.remove('show');
  };

  const getUserBalances = async () => {
    const userBalances = await Promise.all([
      getEthBalance(starz_wallet_address),
      getStzBalance(starz_wallet_address),
    ]);
    setBalances({ eth: +userBalances[0], stz: +userBalances[1] });
  };

  useEffect(() => {
    if (user) {
      dispatch.games.getScholarGames(user.id);
      getUserBalances();
    }
  }, []);

  return (
    <Page>
      <h1 className='title'>My Earnings</h1>
      <Grid>
        <div className='grid-item balance'>
          <div className='title-container'>
            <h4 className='font-size-18 title'>Balance</h4>
          </div>
          <div className='row'>
            <img src={assets.ETH} alt='' />
            <div>
              <p className='font-size-10'>Ethereum Balance</p>
              <p className='font-size-20'>
                {balances.eth}
                <span className='font-size-12'> ETH</span>
              </p>
            </div>
          </div>
          <div className='row exchange'>
            <img src={assets.stzIcon} alt='' />
            <div>
              <p className='font-size-10'>99Starz Balance</p>
              <p className='font-size-20'>
                {balances.stz}
                <span className='font-size-12'> STZ</span>
              </p>
            </div>
            <div className='btn'>
              <Button
                variant='primary'
                onClick={() => dispatch.dialogs.openDialog('exchange')}
              >
                <img src={assets.transfer} alt='' />
              </Button>
            </div>
          </div>
          {Object.keys(tokenBalances)?.map((token, index) => (
            <div className='row' key={index}>
              <img src={tokenBalances[token].logo || assets.stzIcon} alt='' />
              <div>
                <p className='font-size-10'>
                  {tokenBalances[token].name} Balance
                </p>
                <p className='font-size-20'>
                  {tokenBalances[token].balance}
                  <span className='font-size-12'> {token}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
        <ChartContainer>
          <Chart />
        </ChartContainer>
      </Grid>
      {loading && !scholarGames.length ? (
        <Loader />
      ) : (
        <>
          {scholarGames.length ? (
            <TableContainer>
              <table>
                {/* Table Header */}
                <thead>
                  <tr className='header-row'>
                    <th>Game</th>
                    <th>Earning</th>
                    <th>Period</th>
                    <th>Revenue Split</th>
                    <th>Payout Amount</th>
                    <th>Payout Date</th>
                    <th>Wallet Address</th>
                    <th></th>
                  </tr>
                </thead>

                {/* Table body */}
                <tbody>
                  {scholarGames?.map((game, index) => (
                    <tr key={game.id}>
                      <td>
                        <img src={game.logo} alt='' />
                      </td>
                      <td>567.04 STZ</td>
                      <td>2022/02/07 - 2022/02/11</td>
                      <td>{`${user?.scholar_percentage}/${
                        100 - user!.scholar_percentage
                      }`}</td>
                      <td>567.23 STZ</td>
                      <td>2022/02/15</td>
                      <td>{walletAddress}</td>
                      <td>
                        <div className='icon-button'>
                          <img
                            src={assets.moreVert}
                            alt=''
                            onClick={() => handleMenuClick(index)}
                          />
                          <div
                            className='menu'
                            ref={addToRef}
                            onClick={() => {
                              dispatch.dialogs.openDialog('updateWallet');
                              handleCloseMenu(index);
                            }}
                          >
                            <p className='font-size-12'>
                              Update Payout Wallet Address
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
          ) : (
            <p
              className='font-size-18 warn'
              style={{ textAlign: 'center', marginBlock: '100px 50px' }}
            >
              You haven't played any game
            </p>
          )}
        </>
      )}

      <Div />
    </Page>
  );
};

export default MyEarnings;

const Grid = styled.div`
  display: flex;
  grid-gap: 20px;
  margin-block: 40px;
  flex-wrap: wrap;
  align-items: flex-start;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
  & .grid-item {
    flex: 1;
    border: 1px solid #ffffff33;
    padding-block: 24px 22px;
    padding-inline: 30px;
    min-width: 360px;
    @media only screen and (max-width: 700px) {
      min-width: 100px;
      width: 100%;
    }
    & .exchange {
      position: relative;

      & .btn {
        position: absolute;
        right: 0;
        bottom: 0;
        & button {
          height: 40px;
          width: 40px;
          padding: 0;
          clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
          & img {
            display: none;
          }
          & span {
            top: 60%;
            & img {
              display: inline;
              width: 16px;
            }
          }
        }
      }
    }
    & .row {
      display: flex;
      grid-gap: 10px;
      padding: 14px 10px;
      align-items: center;
      border-bottom: 1px solid #ffffff33;
      width: 100%;
      &:last-child {
        padding-block: 14px 0px;
        border: none;
      }

      &.space-between {
        justify-content: space-between;
      }
      &.p-0 {
        padding: 0;
      }
      & img {
        width: 30px;
        &.game-icons {
          width: 50px;
          opacity: 0.4;
        }
      }

      & p.font-size-10 {
        opacity: 0.6;
      }
    }
    & .title-container {
      padding-inline: 10px;
      border-bottom: 1px solid #ffffff33;
      & .title {
        margin-bottom: 20px;
      }
    }
  }
`;

const ChartContainer = styled.div`
  flex: 2;
  /* background-color: red; */
`;

const TableContainer = styled.div`
  overflow-x: auto;
  border: 1px solid #ffffff33;
  padding-inline: 30px;
  padding-bottom: 10px;
  & table {
    border-collapse: collapse;
    min-width: 950px;
    width: 100%;
    & .header-row {
      height: 40px;
      & th {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        opacity: 0.6;
        padding-top: 4px;
        border-bottom: 1px solid #ffffff33;
        text-align: left;
      }
    }
    & tr {
      height: 60px;
    }
    & td {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      border-bottom: 1px solid #ffffff33;
      & img {
        max-height: 40px;
        max-width: 105px;
        margin-left: 10px;
      }

      & .icon-button {
        position: relative;
        & img {
          padding-inline: 5px;
          cursor: pointer;
        }
        & .menu {
          position: absolute;
          top: 0;
          right: 0;
          padding: 14px 20px;
          width: 224px;
          background-color: #0e0e0e;
          transform: translate(-10%, -115%);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          display: none;
          & p {
            opacity: 0.6;
          }
          &.show {
            display: block;
          }
        }
      }
    }
    & tr:last-child {
      & td {
        border: none;
      }
    }
  }
`;

const Div = styled.div`
  height: 60px;
  @media only screen and (max-width: 700px) {
    height: 80px;
  }
`;
