import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Dispatch, RootState } from '../../store';

import Button from '../../components/Button/Button';
import Page from '../../layouts/Page';
import ScholarshipConditions from '../../dialogs/ScholarshipConditions';
import EthAndStz from '../../dialogs/EthAndStz';

// import { mint } from '../../http';
import axios from 'axios';

import theme from '../../theme';

const BadgeSelection: FC = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<Dispatch>();
  const [mintConditions, setMintConditions] = useState(false);
  const [loadingMint, setLoadingMint] = useState(false);

  // Dead Code starts
  const [ETH, setETH] = useState(false);
  const [STZ, setSTZ] = useState(false);
  // Dead Code ends

  const badges = useSelector((state: RootState) => state.badges.badges);
  const selectedGame = useSelector((state: RootState) => state.games.selected);
  const selectedBadge = useSelector(
    (state: RootState) => state.badges.selected
  );

  useEffect(() => {
    dispatch.badges.getAllBadges();
  }, []);

  useEffect(() => {
    if (selectedBadge) {
      setMintConditions(true);
    }
  }, [selectedBadge]);

  const mintBadge = async () => {
    if (!selectedBadge) {
      dispatch.errorModal.openDialog({
        error: 'You must select a badge to continue.',
      });
      return;
    }

    setLoadingMint(true);

    try {
      console.log({
        chain_id: 4, // TODO: make this dynamic later on
        badge_id: selectedBadge.id,
        game_id: selectedGame!.id,
      });
      // const { data } = await mint({
      //   chain_id: 4, // TODO: make this dynamic later on
      //   badge_id: selectedBadge.id,
      //   game_id: selectedGame!.id,
      // });

      // dispatch.nfts.setNfts(data);

      dispatch.dialogs.openDialog('transfer');
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data.message;
        dispatch.errorModal.openDialog({ error: errorMessage });
      }
    } finally {
      setLoadingMint(false);
    }
  };

  if (!selectedGame) {
    return <Navigate to='/dashboard/select-game' replace={true} />;
  }

  return (
    <Page>
      <h1 className='title text-center'>Choose badge:</h1>
      <Grid>
        {badges.map(badge => (
          <div className='grid-item' key={badge.id}>
            <div
              className='content'
              style={{
                backgroundColor:
                  selectedBadge?.id === badge.id ? '#FFFFFF33' : '',
              }}
              onClick={() => dispatch.badges.setSelectedBadge(badge)}
            >
              <div className='logo-container'>
                <img src={badge.logo} alt='' />
              </div>
              <h3 className='title'>{badge.name}</h3>
              <p className='font-size-14'>{badge.description}</p>
            </div>
            <div
              className='underline'
              style={{ width: selectedBadge?.id === badge.id ? '100%' : '' }}
            ></div>
          </div>
        ))}
      </Grid>
      <ButtonContainer>
        <Button
          variant='secondary'
          onClick={() => navigate('/dashboard')}
          disabled={loadingMint}
        >
          Cancel
        </Button>
        <Button variant='primary' onClick={mintBadge} disabled={loadingMint}>
          Mint Badge
        </Button>
      </ButtonContainer>
      <ScholarshipConditions
        open={mintConditions}
        onClose={() => setMintConditions(false)}
      />
      {/* Dead Code starts */}
      <EthAndStz
        open={ETH || STZ}
        setETH={setETH}
        setSTZ={setSTZ}
        showEth={ETH}
        showStz={STZ}
      />
      {/* Dead Code ends */}
    </Page>
  );
};

export default BadgeSelection;

const Grid = styled.div`
  display: flex;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 20px;
  margin-block: 40px 60px;
  flex-wrap: wrap;
  & .grid-item {
    max-width: 310px;
    min-width: 265px;
    & .content {
      min-height: 278px;
      border: 1px solid #ffffff33;
      padding-block: 0px 30px;
      padding-inline: 30px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      & .logo-container {
        flex: 1;
        display: flex;
        align-items: center;
      }
      & h3 {
        margin-block: 0px 14px;
      }
      &:hover ~ .underline {
        width: 100%;
      }
    }
    & .underline {
      height: 4px;
      width: 35%;
      background-color: ${theme.palette.primary};
      position: relative;
      transition: 0.4s ease;
      &::before {
        content: '';
        height: 6px;
        width: 6px;
        background-color: ${theme.palette.black};
        position: absolute;
        right: -3.5px;
        bottom: -3px;
        transform: rotate(45deg);
      }
    }
  }

  @media only screen and (max-width: 700px) {
    & .grid-item {
      max-width: 100%;
      min-width: 100%;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  grid-gap: 20px;
`;
