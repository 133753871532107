import React, { FC } from 'react';

import styled from 'styled-components';

import assets from '../../assets';

interface IProps {
  [x: string]: any;
}

const Footer: FC<IProps> = ({ ...rest }) => {
  return (
    <FooterStyles {...rest}>
      <div className='row'>
        <p className='font-size-12'>
          99Starz Captains are always ready to help!
        </p>

        <a
          href='https://discord.com/invite/eGZUpKp6sr'
          target='_blank'
          rel='noreferrer'
        >
          <img src={assets.discord} style={{ opacity: '0.4' }} alt='' />
        </a>

        <a href='https://t.me/Starz99STZ' target='_blank' rel='noreferrer'>
          <img src={assets.telegram} style={{ opacity: '0.4' }} alt='' />
        </a>
      </div>
    </FooterStyles>
  );
};

export default Footer;

const FooterStyles = styled.div`
  border-top: 1px solid #ffffff33;
  padding-left: 80px;
  & .row {
    padding: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
  }
`;
