import React, { FC } from "react";
import classNames from "classnames";

import styled from "styled-components";

import assets from "../../assets";
import theme from "../../theme";

interface IOption {
  value: string | number;
  label: string;
}

interface IProps {
  options: Array<IOption>;
  title: string;
  name: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<any>) => void;
  error?: string;
}

const Select: FC<IProps> = ({
  options,
  title,
  onChange,
  value,
  name,
  error,
}) => {
  return (
    <Container
      style={{ border: value ? "1px solid #ffffff" : "1px solid #ffffff66" }}
    >
      <select
        value={value === 0 ? "" : value}
        className={classNames(value ? "" : "placeholder", error && "error")}
        onChange={onChange}
        name={name}
      >
        <option value="" disabled>
          Select an option
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <p className={value ? "float" : ""}>{title}</p>
      <span className="arrow">
        <img src={assets.downArrow} alt="" />
      </span>
      {error && <h3 className="invalid-feedback">{error}</h3>}
    </Container>
  );
};

export default Select;

const Container = styled.div`
  position: relative;
  height: 52px;
  border-radius: 2px;
  transition: 0.4s ease;
  & select {
    padding-inline: 16px;
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    color: white;
    font-size: 14px;
    font-weight: 600;
    & option {
      background-color: #121212;
      color: #ffffff;
    }
    &.placeholder {
      color: transparent;
    }
    &:focus {
      border-color: ${theme.palette.primary};
    }
    &.error {
      border-color: ${theme.palette.secondary};
    }
    &:focus ~ p {
      top: -16px;
      left: 16px;
      transform: translateY(0%);
      font-size: 10px;
      opacity: 0.4;
      color: #ffffff;
    }
  }

  & .invalid-feedback {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(calc(100% + 4px));
    font-size: 10px;
    line-height: 16px;
    font-weight: 300;
    color: ${theme.palette.secondary};
    /* margin-top: 4px; */
  }

  & p {
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    color: #ffffff99;
    transition: 0.4s ease;
    pointer-events: none;
    &.float {
      top: -16px;
      left: 16px;
      transform: translateY(0%);
      font-size: 10px;
      opacity: 0.4;
      color: #ffffff;
    }
  }

  & span {
    position: absolute;
    top: 0;
    right: 21px;
    height: 100%;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
