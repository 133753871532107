// eslint-disable-next-line
import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
// eslint-disable-next-line
import countryList from 'react-select-country-list';

interface Iprops {
  options: any;
  value?: any;
  name?: string;
  onChange?: any;
  style?: any;
  [x: string]: any;
}

const CountrySelect: FC<Iprops> = ({ options, value, onChange, style }) => {
  //   const [value, setValue] = useState([]);
  //   const options: any = useMemo(() => countryList().getData(), []);

  //   const changeHandler = (value: any) => {
  //     setValue(value);
  //   };
  //   console.log(value);
  return (
    <Container>
      <Select
        className='selectCountry'
        options={options}
        value={value}
        onChange={onChange}
      />
    </Container>
  );
};

export default CountrySelect;

const Container = styled.div`
  & .selectCountry {
    /* background-color: red; */
    & .css-1s2u09g-control {
      background-color: black !important;
      & .css-319lph-ValueContainer {
        background-color: black !important;
        color: #666666;
        & .css-1hb7zxy-IndicatorsContainer {
          /* & .css-1okebmr-indicatorSeparator {
            display: none !important;
            color: black !important;
            background-color: black !important;
          } */
          span {
            display: none !important ;
          }
        }
        & .css-14el2xx-placeholder {
          background-color: black !important;
          color: #666666 !important;
        }
      }
      & .css-qc6sy-singleValue {
        color: white !important ;
        & .css-6j8wv5-Input {
          & #react-select-3-input::focus {
            background-color: black !important;
            color: #666666 !important;
          }
        }
      }
    }
    & .css-1pahdxg-control {
      background-color: black !important;
      color: #666666 !important ;
    }
    & .css-1pahdxg-control:hover {
      border-color: #151df4 !important;
    }
    & .css-2613qy-menu {
      background-color: black !important;
      color: #666666 !important ;
    }
  }
`;
