import React, { FC, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import styled from 'styled-components';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { object, InferType, string } from 'yup';
import { FormErrors, validateData } from '../../utils/validations';

import Page from '../../layouts/Page';
import InputField from '../../components/InputField/InputField';
import CountryPicker from '../../components/CountryPicker/CountryPicker';
import Button from '../../components/Button/Button';
import NftImage from '../../components/NftImage/NftImage';

import assets from '../../assets';
import { badgeImagesByName } from '../../app.config';
import {
  getUserPayoutHistory,
  updatePayoutWallet,
  updateUser,
} from '../../http';
import { IBadge, IPayoutHistory } from '../../types/interfaces';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import EthAddress from '../../components/EthAddress/EthAddress';

dayjs.extend(utc);

const schema = object({
  nick_name: string().optional().label('Nickname'),
  email: string().email().required().label('Email'),
  country: string().optional().label('Country'),
  discord: string().optional().label('Discord'),
  payoutWalletAddress: string().required().label('Payout wallet address'),
  newPayoutWalletAddress: string().optional().label('Payout wallet address'),
});

interface IUserData extends InferType<typeof schema> {}

const UserProfile: FC = () => {
  const [tab, setTab] = useState(0);

  const dispatch = useDispatch<Dispatch>();
  const user = useSelector((state: RootState) => state.auth.user);
  const accounts = useSelector((state: RootState) => state.auth.walletAccounts);
  const badges = useSelector((state: RootState) => state.badges.badges);
  const mintedBadges = useSelector(
    (state: RootState) => state.badges.mintedBadges
  );
  const nfts = useSelector((state: RootState) => state.nfts.nfts);

  const scholarGames = useSelector(
    (state: RootState) => state.games.scholarGames
  );

  const [maxBadge, setMaxBadge] = useState<IBadge | null>(null);
  const [loading, setLoading] = useState(false);
  const [payoutHistory, setPayoutHistory] = useState<IPayoutHistory[]>([]);

  const [userData, setUserData] = useState<IUserData>({
    nick_name: user?.nick_name || '',
    email: user?.email || '',
    country: user?.country || '',
    discord: user?.discord || '',
    payoutWalletAddress: accounts[0]?.wallet_address || '',
    newPayoutWalletAddress: '',
  });

  const [errors, setErrors] = useState<FormErrors<IUserData | null>>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setUserData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: null }));
  };

  const handleUpdateUser = async () => {
    setErrors(null);
    const { errors: validationErrors } = await validateData<IUserData>(
      schema,
      userData
    );
    if (validationErrors) {
      return setErrors(validationErrors);
    }

    try {
      setLoading(true);
      const { payoutWalletAddress, newPayoutWalletAddress, email, ...apiData } =
        userData;
      await updateUser(apiData);
      if (newPayoutWalletAddress) {
        if (ethers.utils.isAddress(newPayoutWalletAddress)) {
          await updatePayoutWallet({
            public_address: payoutWalletAddress,
            new_public_address: newPayoutWalletAddress,
          });
          // alert("password updated");
          toast.success('Updated Wallet Successfully...!');
          dispatch.auth.getUser();
        } else {
          setErrors(prev => ({
            ...prev,
            newPayoutWalletAddress: 'Invalid address',
          }));
        }
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getFormattedDate = (date: string): string => {
    const formattedDate = dayjs
      .utc(date)
      .local()
      .format('YYYY-DD-MM  hh:mm:ss');
    return formattedDate;
  };

  const getMaxBadge = () => {
    let maxBadge = mintedBadges[0];
    mintedBadges.forEach(badge => {
      if (maxBadge.badge_type < badge.badge_type) {
        maxBadge = badge;
      }
    });

    const _maxBadge = badges.find(
      badge => badge.badge_type === maxBadge.badge_type
    )!;
    setMaxBadge(_maxBadge);
  };

  const handleGetPayoutHistory = async (userId: string) => {
    try {
      const { data } = await getUserPayoutHistory(userId);
      setPayoutHistory(data);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (user) {
      dispatch.badges.getMintedBadges(user.id);
      dispatch.games.getScholarGames(user.id);
      // handleGetPayoutHistory(user.id);
      if (accounts.length) {
        dispatch.nfts.getScholarNfts({
          public_address: accounts[0]?.starz_wallet_address,
          user_id: user.id,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (mintedBadges.length) {
      getMaxBadge();
    }
  }, [mintedBadges]);

  useEffect(() => {
    if (user) {
      handleGetPayoutHistory(user.id);
      setUserData({
        nick_name: user?.nick_name || '',
        email: user?.email || '',
        country: user?.country || '',
        discord: user?.discord || '',
        payoutWalletAddress: accounts[0]?.wallet_address,
        newPayoutWalletAddress: '',
      });
    }
  }, [user, accounts]);
  // console.log(user);

  return (
    <Page>
      <Container>
        <ProfileAndAssets>
          <div className='wrapper'>
            <div className='user-image'>
              <img src={user?.image_url || assets.blank} alt='' />
            </div>
            <p className='font-size-18 title'>
              {user?.nick_name || user?.fullname}
            </p>
            <div className='row highest-badge'>
              {maxBadge && (
                <img src={badgeImagesByName[maxBadge.name]} alt='' />
              )}

              <p className='font-size-14'>Scholar</p>
            </div>

            {scholarGames.length && (
              <>
                <p className='font-size-14 title'>Games</p>
                <Divider />
                <UserGamesAndAssets>
                  {/* user games go here */}
                  {scholarGames.map(game => (
                    <div className='user-game' key={game.id}>
                      <img src={game.mini_icon} alt='' />
                    </div>
                  ))}
                </UserGamesAndAssets>
              </>
            )}

            {nfts.length && (
              <>
                <p className='font-size-14 title'>Assets</p>
                <Divider />

                <UserGamesAndAssets className='assets'>
                  {/* user assets go here */}
                  {nfts.map((nft, index) => (
                    <NftImage nftData={nft} size={36} key={index} />
                  ))}
                </UserGamesAndAssets>
              </>
            )}
          </div>
        </ProfileAndAssets>
        <Information>
          <Tabs>
            <p
              className={classNames(
                'font-size-14 title tab',
                tab === 0 && 'active'
              )}
              onClick={() => setTab(0)}
            >
              Account Information
            </p>
            <p
              className={classNames(
                'font-size-14 title tab',
                tab === 1 && 'active'
              )}
              onClick={() => setTab(1)}
            >
              Payout History
            </p>
          </Tabs>

          {tab === 0 && (
            <TabPanel>
              <div className='grid'>
                <InputField
                  placeholder='Nickname*'
                  type='text'
                  name='nick_name'
                  value={userData.nick_name}
                  onChange={handleChange}
                  error={errors?.nick_name}
                />

                <InputField
                  placeholder='Email Address'
                  type='text'
                  name='email'
                  value={userData.email}
                  onChange={handleChange}
                  error={errors?.email}
                  disabled
                />

                <CountryPicker
                  onChange={handleChange}
                  title='Country*'
                  value={userData?.country || ''}
                  name='country'
                  error={errors?.country}
                />
                <InputField
                  placeholder='Your Discord Handle'
                  type='text'
                  name='discord'
                  value={userData.discord}
                  onChange={handleChange}
                  error={errors?.discord}
                />
                <InputField
                  placeholder='Payout Wallet Address'
                  type='text'
                  name='payoutWalletAddress'
                  value={userData.payoutWalletAddress}
                  onChange={handleChange}
                  disabled
                />
                <InputField
                  placeholder='New Payout Wallet Address*'
                  type='text'
                  name='newPayoutWalletAddress'
                  value={userData.newPayoutWalletAddress}
                  onChange={handleChange}
                  error={errors?.newPayoutWalletAddress}
                />
              </div>
              <ButtonContainer>
                <Button variant='secondary' disabled={loading}>
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  disabled={loading}
                  onClick={handleUpdateUser}
                >
                  Save Changes
                </Button>
              </ButtonContainer>
            </TabPanel>
          )}
          {tab === 1 && (
            <TabPanel>
              {payoutHistory.length && (
                <TableContainer>
                  <table>
                    <thead>
                      <tr className='header-row'>
                        <th>Wallet Address</th>
                        <th>Scholar wallet Address</th>
                        <th>Payout</th>
                        <th>Payout Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payoutHistory.map((history, index) => (
                        <tr key={index}>
                          <td>
                            <EthAddress
                              address={accounts[0]?.wallet_address || ''}
                            />
                          </td>
                          <td>
                            <EthAddress
                              address={accounts[0]?.starz_wallet_address || ''}
                            />
                          </td>
                          <td>
                            <div className='row'>
                              <p className='font-size-14'>
                                {history.payout_amount_user}{' '}
                                {history.payout_symbol}
                              </p>
                            </div>
                          </td>
                          <td>{getFormattedDate(history.created_at)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TableContainer>
              )}
            </TabPanel>
          )}
        </Information>
      </Container>
    </Page>
  );
};

export default UserProfile;

const Container = styled.div`
  margin-block: 80px 60px;
  display: flex;
  gap: 64px;
  align-items: stretch;

  @media only screen and (max-width: 950px) {
    flex-direction: column;
  }
`;

const ProfileAndAssets = styled.div`
  flex: 0 0 39%;
  max-width: 456px;
  padding-bottom: 40px;
  & .wrapper {
    background-image: url(${assets.glitch});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-inline: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & .user-image {
      margin-top: -40px;
      & img {
        height: 144px;
        width: 144px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    & .row {
      display: flex;
      align-items: center;
      gap: 8px;

      &.highest-badge {
        & img {
          width: 25px;
        }
      }
    }

    & p.title {
      margin-top: 30px;
      margin-bottom: 8px;
    }
  }

  & .btn {
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 950px) {
    flex: 0 0 100%;
    margin-inline: auto;

    & .wrapper {
      padding-inline: 20px;
    }
  }
`;

const UserGamesAndAssets = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
  padding-inline: 24px;
  flex-wrap: wrap;

  &.assets {
    gap: 8px;
  }

  & .user-game {
    height: 48px;
    width: 48px;
    border-radius: 12px;
    background-color: #ffffff1a;
    padding: 10px;
    & img {
      width: 100%;
    }
  }

  & .user-asset {
    height: 36px;
    width: 36px;

    & img {
      width: 100%;
    }
  }
`;

const Information = styled.div`
  flex-grow: 1;
`;

const Divider = styled.div`
  border-top: 1px solid #ffffff33;
  width: 100%;
`;

const Tabs = styled.div`
  display: flex;
  gap: 32px;
  padding-inline: 16px;
  border-bottom: 1px solid #ffffff33;
  padding-block: 8px;

  & .tab {
    cursor: pointer;
    opacity: 0.4;
    transition: 0.4s ease;
    &.active {
      opacity: 1;
    }
  }
`;

const TabPanel = styled.div`
  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px 38px;
    margin-top: 52px;
  }

  @media only screen and (max-width: 420px) {
    & .grid {
      grid-template-columns: 1fr;
    }
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
  border: 1px solid #ffffff33;
  padding-bottom: 10px;
  width: 100%;
  margin-block: 30px 60px;
  border-radius: 4px;
  & table {
    border-collapse: collapse;
    min-width: 650px;
    width: 100%;
    & tbody {
      padding-inline: 10px;
    }
    & .header-row {
      height: 40px;
      background-color: #0e0e0e;
      & th {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        opacity: 0.6;
        padding-top: 4px;
        border-bottom: 1px solid #ffffff33;
        text-align: left;
        &:first-of-type {
          padding-left: 32px;
        }

        & .row {
          display: flex;
          align-items: center;
          gap: 8px;

          & .col {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
      }
    }
    & tr {
      height: 60px;
    }
    & td {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      border-bottom: 1px solid #ffffff33;
      padding-block: 12px;
      &:first-of-type {
        padding-left: 32px;
      }

      &:last-of-type {
        padding-right: 10px;
      }

      & .row {
        display: flex;
        align-items: center;
        gap: 10px;

        & img {
          width: 20px;
        }
      }

      & .icon-button {
        & img {
          padding-inline: 5px;
          cursor: pointer;
        }
        & .menu {
          position: absolute;
          width: 200px;
          background: #0e0e0e;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          transform: translate(-90%, -115%);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          display: none;
          & .menu-item {
            padding: 10px 5px;
            cursor: pointer;
            &:hover {
              background-color: #000000;
            }
          }
          & p {
            opacity: 0.6;
          }
          &.show {
            display: block;
          }
        }
      }
    }
    & tr:last-child {
      & td {
        border: none;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
`;
