import { createGlobalStyle } from 'styled-components';
import theme from '.';

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Krona One', sans-serif;
  font-family: 'Montserrat', sans-serif;
}

body {
  background-color: #000000;
  
  position: relative;
}
h1 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

h2 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400px;
}

h3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

h1,h2,h3,h4,h5,h6,p,th,td{
  color: #ffffff
}



.apexcharts-tooltip-marker{
  color: #000000;
}

.font-size-10 {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: #ffffff
}

.font-size-12 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #ffffff
}

.font-size-14 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #ffffff
}

.font-size-16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff
}

.font-size-18 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #ffffff
}

.font-size-20 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  color: #ffffff
}

.font-size-24 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  color: #ffffff
}

.font-size-8{
  font-size: 8px;
  line-height: 14px;
  font-weight: 400;
}

.title {
  font-family: 'Krona One', sans-serif;
}

.anchor {
  text-decoration: none;
  color: ${theme.palette.anchor};
}

.anchor-secondary {
  text-decoration: none;
  color: ${theme.palette.secondary};
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.text-center {
 
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button{
  display: none;
  margin: 0;
}

/* .splide__arrows svg{
 
}

.splide__arrows svg path{
  fill: #ffffff
}

.splide__arrow {
  height: 100%;
  width: 70px;
  border-radius: 0px;
  top: 49%;
 
}

.splide__arrow--prev{
  left: 0;
  background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}

.splide__arrow--next{
  right: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
} */

@media only screen and (max-width: 700px) {
  .title {
    font-size: 20px;
    line-height: 28px;
  }
}



.spinner{

  display: flex;
  justify-content: center;
  padding: 10px 0px;

  & div{
    height: 50px;
  width: 50px;
  border-top: 1px solid ${theme.palette.primary};
  border-bottom: 1px solid ${theme.palette.primary};
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  border-radius: 50%;
  animation: rotating 3s linear infinite;
  }
}

.loader{
  animation: rotating 3s linear infinite; 
}

.warn{
  display: flex;
  justify-content: center;
  padding: 10px 0px;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

`;
