import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import axios from 'axios';
import classNames from 'classnames';

import theme from '../../theme';
import assets from '../../assets';

interface IProps {
  title: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  error?: string;
  [x: string]: any;
}

const CountryPicker: FC<IProps> = ({
  title,
  value,
  onChange,
  error,
  ...rest
}) => {
  const [countries, setCountries] = useState([]);
  // eslint-disable-next-line
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    axios.get('https://restcountries.com/v3.1/all').then(res => {
      const list = res.data;
      list.sort(function (a: any, b: any) {
        var textA = a.name.common.toUpperCase();
        var textB = b.name.common.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setCountries(list);
    });
  }, []);

  return (
    <Picker>
      <select
        name=''
        className={classNames(error && 'error')}
        id=''
        value={value}
        onChange={onChange}
        {...rest}
      >
        <option value=''></option>
        {countries.map((country: any, index) => (
          <option value={country?.name?.common} key={index}>
            {`${country?.flag}  ${country?.name.common}`}
          </option>
        ))}
      </select>
      <p className={value ? 'float' : ''}>{title}</p>
      <span className='arrow'>
        <img src={assets.downArrow} alt='' />
      </span>
      {error && <h3 className='invalid-feedback'>{error}</h3>}
    </Picker>
  );
};

export default CountryPicker;

const Picker = styled.div`
  position: relative;
  height: 48px;

  & select {
    padding-block: 10px;
    padding-inline: 0px 30px;
    width: 100%;
    border: none;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    color: white;
    border: 1px solid #ffffff;
    height: 48px;
    padding-inline: 16px;
    font-size: 14px;
    margin-top: 3px;
    font-weight: 600;
    &.placeholder {
      color: transparent;
    }
    &:focus {
      border-color: ${theme.palette.primary};
    }
    &.error {
      border-color: ${theme.palette.secondary};
    }
    &:focus ~ p {
      top: -16px;
      left: 8px;
      transform: translateY(0%);
      font-size: 10px;
      opacity: 0.4;
      color: #ffffff;
    }

    & option {
      background-color: #121212;
      color: #ffffff;
    }
  }

  & .invalid-feedback {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(calc(100% + 4px));
    font-size: 10px;
    line-height: 16px;
    font-weight: 300;
    color: ${theme.palette.secondary};
    /* margin-top: 4px; */
  }

  & p {
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-35%);
    color: #ffffff99;
    transition: 0.4s ease;
    pointer-events: none;
    &.float {
      top: -16px;
      left: 8px;
      transform: translateY(0%);
      font-size: 10px;
      opacity: 0.4;
      color: #ffffff;
    }
  }

  & span {
    position: absolute;
    transform: translateY(3px);
    top: 0;
    right: 7px;
    height: 100%;
    width: 30px;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
