import React, { FC, useState, useMemo } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { object, string, InferType } from 'yup';
import { FormErrors, validateData } from '../../utils/validations';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import countryList from 'react-select-country-list';

import LoginLayout from '../../layouts/LoginLayout';
import InputField from '../../components/InputField/InputField';
import Button from '../../components/Button/Button';
// import CountryPicker from "../../components/CountryPicker/CountryPicker";
import ImagePicker from '../../components/ImagePicker/ImagePicker';
import CountrySelect from '../../components/CountryPicker/CountrySelect';

const schema = object({
  nick_name: string().required().label('Nickname'),
  discord: string().optional().label('Discord handle'),
  country: string().optional().label('Country'),
});

interface ProfileData extends InferType<typeof schema> {}

const ProfileSetup: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [selectedCountry, setSelectedCountry] = useState<any>([]);
  const loading = useSelector((state: RootState) => state.auth.loading);
  const countryOptions: any = useMemo(() => countryList().getData(), []);
  const [image, setImage] = useState<File | null>(null);
  const [userInfo, setUserInfo] = useState<ProfileData>({
    nick_name: '',
    discord: '',
    country: '',
  });

  const [errors, setErrors] = useState<FormErrors<ProfileData> | null>(null);

  const onChangeHanlder = (value: any) => {
    setSelectedCountry(value);
    setUserInfo(prev => ({ ...prev, country: value.label }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.currentTarget;
    setUserInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleCreateAccount = async () => {
    const { data, errors: validationErrors } = await validateData<ProfileData>(
      schema,
      userInfo
    );
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }

    if (image) {
      const imageData = new FormData();

      // @ts-ignore
      imageData.append('image', image);
      dispatch.auth.addProfileData({
        payload: data,
        image: imageData,
        navigate,
      });
    } else {
      dispatch.auth.addProfileData({ payload: data, image: null, navigate });
    }
    // console.log(selectedCountry);
    console.log(userInfo);
  };

  return (
    <LoginLayout>
      <Container>
        <h1 className='title'>Setup your profile, Scholar!</h1>

        <div className='image-picker'>
          <ImagePicker image={image} setImage={setImage} />
        </div>

        <FieldContainer>
          <InputField
            placeholder='Nickname*'
            type='text'
            name='nick_name'
            value={userInfo.nick_name}
            onChange={handleChange}
            error={errors?.nick_name}
          />
        </FieldContainer>

        {/* <FieldContainer>
          <CountryPicker
            title="Country"
            value={userInfo.country || ""}
            name="country"
            onChange={handleChange}
            error={errors?.country}
          />
        </FieldContainer> */}

        <FieldContainer>
          <CountrySelect
            name='country'
            options={countryOptions}
            value={selectedCountry}
            onChange={onChangeHanlder}
          />
        </FieldContainer>

        <FieldContainer>
          <InputField
            placeholder='What is Your Discord handle?'
            type='text'
            name='discord'
            value={userInfo.discord}
            onChange={handleChange}
            error={errors?.discord}
          />
        </FieldContainer>

        <Button
          variant='primary'
          className='btn'
          onClick={handleCreateAccount}
          disabled={loading}
        >
          Create account
        </Button>

        {/* <HelperLink className='font-size-14'>
          Having trouble?{' '}
          <Link to='#' className='anchor'>
            Contact customer service
          </Link>
          .
        </HelperLink> */}
      </Container>
    </LoginLayout>
  );
};

export default ProfileSetup;

const Container = styled.div`
  & .image-picker {
    margin-block: 40px 50px;
  }
  & .btn {
    width: 100%;
  }
`;

const FieldContainer = styled.div`
  margin-bottom: 38px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 36px;
  }
`;
