import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

import DialogLayout from './Dialog';

import assets from '../assets';

import styled from 'styled-components';
import { BadgesEnum } from '../types/enums';
import InputField from '../components/InputField/InputField';
import Button from '../components/Button/Button';

interface Iprops {
  open: boolean;
  onClose: () => void;
}

const ScholarshipConditions: FC<Iprops> = ({ open, onClose }) => {
  const walletAccounts = useSelector(
    (state: RootState) => state.auth.walletAccounts
  );

  const selectedGame = useSelector((state: RootState) => state.games.selected);
  const selectedBadge = useSelector(
    (state: RootState) => state.badges.selected
  );

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='close-icon' onClick={onClose}>
          <img src={assets.closeIcon} alt='' />
        </div>
        <div className='content'>
          <h1 className='title'>
            Scholarship
            <br /> conditions:
          </h1>
          <p className='font-size-14 my'>
            Minting the badge requires a payment of STZ and ETH tokens. These
            token amounts should be available in your scholar wallet balance, to
            acquire the badge:
          </p>
          <div className='row'>
            <img src={assets.ETH} alt='' className='icon' />
            <div>
              <p className='font-size-10'>For the minting gas fee</p>
              <p className='font-size-20'>
                0.1 <span className='font-size-12 bold'>ETH</span>
              </p>
            </div>
          </div>
          <div className='divider'></div>
          <div className='row'>
            <img src={assets.stzIcon} alt='' className='icon' />
            <div>
              {selectedBadge?.badge_type === BadgesEnum.GOLD && (
                <>
                  <p className='font-size-10'>For the {selectedBadge.name}</p>
                  <p className='font-size-20'>
                    {selectedGame?.minimum_gold_badge_token}{' '}
                    <span className='font-size-12 bold'>STZ</span>
                  </p>
                </>
              )}
              {selectedBadge?.badge_type === BadgesEnum.SILVER && (
                <>
                  <p className='font-size-10'>For the {selectedBadge.name}</p>
                  <p className='font-size-20'>
                    {selectedGame?.minimum_silver_badge_token}{' '}
                    <span className='font-size-12 bold'>STZ</span>
                  </p>
                </>
              )}
              {selectedBadge?.badge_type === BadgesEnum.BRONZE && (
                <>
                  <p className='font-size-10'>For the {selectedBadge.name}</p>
                  <p className='font-size-20'>
                    {selectedGame?.minimum_bronze_badge_token}{' '}
                    <span className='font-size-12 bold'>STZ</span>
                  </p>
                </>
              )}
            </div>
          </div>

          <p className='font-size-12 my'>
            Transfer the tokens to your scholar wallet with the address provided
            below. Once you've made the transaction, continue with the next
            steps.
          </p>

          <div style={{ marginBottom: '30px' }}>
            {selectedBadge?.badge_type === BadgesEnum.GOLD && (
              <p>
                For {selectedBadge.name}:{' '}
                {selectedGame?.minimum_gold_badge_token} STZ
              </p>
            )}
            {selectedBadge?.badge_type === BadgesEnum.SILVER && (
              <p>
                For {selectedBadge.name}:{' '}
                {selectedGame?.minimum_silver_badge_token} STZ
              </p>
            )}
            {selectedBadge?.badge_type === BadgesEnum.BRONZE && (
              <p>
                For {selectedBadge.name}:{' '}
                {selectedGame?.minimum_bronze_badge_token} STZ
              </p>
            )}
          </div>
          <InputField
            style={{ background: 'transparent' }}
            placeholder='Scholar wallet'
            type='text'
            className='payout'
            value={walletAccounts[0].starz_wallet_address || ''}
            disabled
          />
          <div className='btn-wrap'>
            <Button variant='primary' onClick={onClose}>
              Acknowledged
            </Button>
          </div>
        </div>
      </Container>
    </DialogLayout>
  );
};

export default ScholarshipConditions;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 420px;

  @media only screen and (max-width: 440px) {
    min-width: 320px;
  }

  & .close-icon {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    & img {
      cursor: pointer;
    }
  }
  & .content {
    margin-top: 6px;
    padding: 30px 30px;
    background-color: #121212;

    @media only screen and (max-width: 400px) {
      padding: 30px 20px;
    }
    /* & p.margin {
      margin-block: 35px;
    }
    & p.mt {
      margin-top: 35px;
    } */

    & p.my {
      margin-block: 20px;
    }

    & .row {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    & .divider {
      border-top: 1px solid #ffffff33;
      margin-block: 14px;
    }

    & .btn-wrap {
      display: flex;
      justify-content: center;
      margin-top: 60px;
    }
  }
`;
