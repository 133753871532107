import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { getScholarships } from '../../http';
import { IScholarship } from '../../types/interfaces';

interface IState {
  scholarships: IScholarship[];
  loading: boolean;
}

export const scholarships = createModel<RootModel>()({
  name: 'scholarships',
  state: {
    scholarships: [],
    loading: false,
  } as IState,
  reducers: {
    setScholarships(state, payload: IScholarship[]) {
      state.scholarships = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
  },
  effects: dispatch => ({
    async getAllScholarships() {
      try {
        dispatch.scholarships.setLoading(true);
        let { data } = await getScholarships();
        if (data.length > 4) {
          data = data.slice(0, 4);
        }
        dispatch.scholarships.setScholarships(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.scholarships.setLoading(false);
      }
    },
  }),
});
