import { createModel } from '@rematch/core';
import { RootModel } from '.';

interface IState {
  open: boolean;
  title: string;
  subtitle: string;
}

export const feedbackModal = createModel<RootModel>()({
  name: 'feedbackModal',
  state: {
    open: false,
    title: '',
    subtitle: '',
  } as IState,
  reducers: {
    open(state, payload: { title: string; subtitle: string }) {
      state.open = true;
      state.title = payload.title;
      state.subtitle = payload.subtitle;
    },
    close(state) {
      state.open = false;
      state.title = '';
      state.subtitle = '';
    },
  },
});
