import React, { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';

import Page from '../../layouts/Page';
import Button from '../../components/Button/Button';
import styled from 'styled-components';
import theme from '../../theme';

const GameSelection: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const games = useSelector((state: RootState) => state.games.games);
  const selectedGame = useSelector((state: RootState) => state.games.selected);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch.games.getAllGames();
  }, []);

  return (
    <Page>
      <h1 className='title text-center'>Choose game:</h1>
      <Grid>
        {games.map(game => (
          <div className='grid-item' key={game.id}>
            <div
              className='content'
              style={{
                backgroundColor:
                  selectedGame?.id === game.id ? '#FFFFFF33' : '',
              }}
              onClick={() => dispatch.games.setSelectedGame(game)}
            >
              <div className='logo-container'>
                <img src={game.logo} className='game_logo' alt='' />
              </div>
              <p className='font-size-14'>{game.description}</p>
            </div>
            <div
              className='underline'
              style={{ width: selectedGame?.id === game.id ? '100%' : '' }}
            ></div>
          </div>
        ))}
      </Grid>
      <ButtonContainer>
        <Button variant='secondary' onClick={() => navigate('/dashboard')}>
          Cancel
        </Button>
        <Button
          variant='primary'
          onClick={() => {
            if (!selectedGame) {
              dispatch.errorModal.openDialog({
                error: 'You must select one of the game to continue.',
              });
              return;
            }
            navigate('/dashboard/select-badge');
          }}
        >
          Continue
        </Button>
      </ButtonContainer>
    </Page>
  );
};

export default GameSelection;

const Grid = styled.div`
  margin-block: 40px 60px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  grid-gap: 20px;

  & .grid-item {
    & .content {
      min-height: 200px;
      border: 1px solid #ffffff33;
      padding-block: 0px 30px;
      padding-inline: 30px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      & .logo-container {
        flex: 1;
        display: flex;
        align-items: center;
      }
      &:hover ~ .underline {
        width: 100%;
      }
      & .game_logo {
        max-height: 50px;
      }
    }
    & .underline {
      height: 4px;
      width: 35%;
      background-color: ${theme.palette.primary};
      position: relative;
      transition: 0.4s ease;
      &::before {
        content: '';
        height: 6px;
        width: 6px;
        background-color: ${theme.palette.black};
        position: absolute;
        right: -3.5px;
        bottom: -3px;
        transform: rotate(45deg);
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  & button {
    margin-inline: 10px;
  }
`;
