import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import dayjs from 'dayjs';

import Button from '../../components/Button/Button';
import ApplyScholarshipDialog from '../../dialogs/ApplyScholarshipDialog';
import ScreenshotSlider from '../../components/ScreenshotSlider/ScreenshotSlider';

import { getGameById } from '../../http';
import { IBadge, IGameDetails, IMintedBadge } from '../../types/interfaces';

import { badgeImagesByName } from '../../app.config';

const GameDetails: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const user = useSelector((state: RootState) => state.auth.user);
  const badges = useSelector((state: RootState) => state.badges.badges);
  const mintedBadges = useSelector(
    (state: RootState) => state.badges.mintedBadges
  );

  const [notMintedBadges, setNotMintedBadges] = useState<IBadge[]>([]);

  const [apply, setApply] = useState(false);
  const [game, setGame] = useState<IGameDetails | null>(null);

  useEffect(() => {
    const remainingBadges: IBadge[] = [];
    let highestBadge: IMintedBadge;
    if (mintedBadges.length) {
      highestBadge = mintedBadges[0];
      mintedBadges.forEach(mb => {
        if (mb.badge_type > highestBadge.badge_type) {
          highestBadge = mb;
        }
      });
      badges.forEach(b => {
        if (b.badge_type > highestBadge.badge_type) {
          remainingBadges.push(b);
        }
      });
      setNotMintedBadges(remainingBadges);
    } else {
      setNotMintedBadges(badges);
    }

    // eslint-disable-next-line
  }, [mintedBadges, badges]);

  useEffect(() => {
    (async () => {
      dispatch.badges.getAllBadges();
      if (user) {
        dispatch.badges.getMintedBadges(user.id);
      }
      // @ts-ignore
      const { data } = await getGameById(params?.gameId);
      setGame(data);
    })();
  }, []);
  return (
    <>
      <Hero
        style={{
          background: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%),
    url(${game?.cover_photo})`,
        }}
      >
        <div>
          <img src={game?.logo} className='game-logo' alt='' />
        </div>
        <p className='font-size-18'>{game?.short_description}</p>

        <div className='btn-container'>
          {/* <Button variant='secondary'>Play Game</Button> */}

          {/* <Button variant='primary'>Mint a Bronze Badge</Button>
          <Button variant='primary'>Mint a Silver Badge</Button>
          <Button variant='primary'>Mint a Gold Badge</Button> */}

          {mintedBadges.length && (
            <Button
              variant={notMintedBadges.length ? 'secondary' : 'transparent'}
              onClick={() => {
                if (game) {
                  dispatch.games.setSelected(game);
                  setApply(true);
                }
              }}
            >
              Apply for scholarship
            </Button>
          )}

          {notMintedBadges.map(badge => (
            <div className='membership' key={badge.id}>
              <div>
                <Button
                  variant='primary'
                  style={{ textTransform: 'none' }}
                  onClick={() => {
                    if (game) {
                      dispatch.games.setSelected(game);
                      dispatch.badges.setSelectedBadge(badge);
                      navigate('/dashboard/mint-badge');
                    }
                  }}
                >
                  Mint a {badge.name}
                </Button>
              </div>
              <div className='row'>
                <img src={badgeImagesByName[badge.name]} alt='' />
                <p className='font-size-8'>Required</p>
              </div>
            </div>
          ))}
        </div>
      </Hero>
      {game && (
        <ScreenshotSection>
          <h2 className='title'>In-game Screenshots</h2>
          {/* TODO : Add screenshot slider here */}
          <ScreenshotSlider screenshots={game.ScreenShots} />
        </ScreenshotSection>
      )}

      <AboutSection>
        <div className='info'>
          <h2 className='title'>About {game?.name}</h2>
          <p className='font-size-14'>{game?.description}</p>
          {/* <br />
          <p className='font-size-14'>
            The Axie core team will build functionality for the game assets
            associated with the Axie brand in concurrence with the community.
            The Axie universe must be fun above all else; only by having an
            immersive gaming experience can the ecosystem attract enough
            participants to accrue value to the network.
          </p> */}
        </div>
        <div className='details'>
          <div className='row'>
            <p className='font-size-12 key'>Release Date</p>
            <p className='font-size-12'>
              {game?.release_date &&
                dayjs(game.release_date).format('MMMM YYYY')}
            </p>
          </div>
          <div className='row'>
            <p className='font-size-12 key'>Genre(s)</p>
            <p className='font-size-12'>{game?.genres}</p>
          </div>
          <div className='row'>
            <p className='font-size-12 key'>Mode(s)</p>
            <p className='font-size-12'>{game?.modes}</p>
          </div>
          <div className='row'>
            <p className='font-size-12 key'>Creator(s)</p>
            <p className='font-size-12'>{game?.creators}</p>
          </div>
          <div className='row'>
            <p className='font-size-12 key'>Developer(s)</p>
            <p className='font-size-12'>{game?.developers}</p>
          </div>
          <div className='row'>
            <p className='font-size-12 key'>Platform(s)</p>
            <p className='font-size-12'>{game?.platforms}</p>
          </div>
        </div>
      </AboutSection>
      {/* <SimilarGames>
        <h2 className='title mb'>Similiar Games</h2>
        <div className='grid'>
          <div className='grid-item'>
            <Game />
          </div>
          <div className='grid-item'>
            <Game />
          </div>
          <div className='grid-item'>
            <Game />
          </div>
          <div className='grid-item'>
            <Game />
          </div>
        </div>
      </SimilarGames> */}
      <BackButtonContainer>
        <Button variant='secondary' onClick={() => navigate(-1)}>
          Back to Games
        </Button>
      </BackButtonContainer>

      {apply && <ApplyScholarshipDialog open={apply} setOpen={setApply} />}
    </>
  );
};

export default GameDetails;

const Hero = styled.div`
  min-height: calc(100vh - 112px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 56px;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;

  & .game-logo {
    min-width: 140px;
    max-width: 180px;
  }

  & p {
    max-width: 40ch;
    margin-block: 34px 60px;
  }

  & .btn-container {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    & .membership {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      & .row {
        margin-top: 16px;
        display: flex;
        align-items: center;
        gap: 10px;
        & img {
          width: 30px;
        }
        & p.font-size-8 {
          margin: 0;
        }
      }
    }
  }
  @media only screen and (max-width: 700px) {
    height: calc(100vh - 80px);
    margin-top: 0px;
    padding: 25px;
    & .game-logo {
      min-width: 100px;
      max-width: 100px;
    }

    & p {
      max-width: 40ch;
      margin-block: 24px 50px;
      font-size: 15px;
    }

    & .btn-container {
      display: flex;
      align-items: flex-start;
      gap: 20px;

      & button {
        height: 50px;
      }

      & .membership {
        & .row {
          margin-top: 16px;
          display: flex;
          align-items: center;
          gap: 10px;
          & img {
            width: 30px;
          }
          & p.font-size-8 {
            margin: 0;
          }
        }
      }
    }
  }
`;

const ScreenshotSection = styled.section`
  padding: 60px 56px;

  @media only screen and (max-width: 700px) {
    padding: 40px 25px;
  }
  @media only screen and (min-width: 1444px) {
    margin-bottom: 60px;
  }
`;

const AboutSection = styled.section`
  padding: 0px 56px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  & .info {
    & p.font-size-14 {
      max-width: 53ch;
      &:first-of-type {
        margin-top: 30px;
      }
    }
  }

  & .details {
    background-color: #121212;
    border-radius: 4px;
    padding: 10px 20px;

    & .row {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ffffff33;
      padding: 15px 12px;
      &:last-of-type {
        border-bottom: none;
      }
      & .key {
        opacity: 0.4;
        min-width: 13ch;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  @media only screen and (max-width: 700px) {
    padding: 0px 25px;
  }
`;
// eslint-disable-next-line
const SimilarGames = styled.section`
  margin-block: 60px 44px;
  padding: 0px 56px;

  & h2.mb {
    margin-bottom: 20px;
  }

  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
  }

  @media only screen and (max-width: 700px) {
    padding: 0px 25px;
    & .grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media only screen and (max-width: 500px) {
    & .grid {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

const BackButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 90px;
  margin-top: 30px;
`;
