import React, { FC, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../store';
import { object, string, InferType } from 'yup';
import { FormErrors, validateData } from '../utils/validations';

import DialogLayout from './Dialog';
import Button from '../components/Button/Button';
import InputField from '../components/InputField/InputField';

import assets from '../assets';

const schema = object({
  public_address: string()
    .matches(
      /^0x[a-fA-F0-9]{40}$/,
      'Payout wallet address must be a valid ethereum address'
    )
    .required()
    .label('Payout wallet address'),
  new_public_address: string()
    .matches(
      /^0x[a-fA-F0-9]{40}$/,
      'New Payout wallet address must be a valid ethereum address'
    )
    .required()
    .label('New Payout wallet address'),
});

interface IData extends InferType<typeof schema> {}

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateWallet: FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();

  const loading = useSelector((state: RootState) => state.auth.loading);
  const accounts = useSelector((state: RootState) => state.auth.walletAccounts);

  const [formData, setFormData] = useState<IData>({
    public_address: accounts[0]?.wallet_address || '',
    new_public_address: '',
  });

  const [errors, setErrors] = useState<FormErrors<IData> | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: null }));
  };

  const handleSubmit = async () => {
    setErrors(null);
    const { data, errors: ValidationErrors } = await validateData<IData>(
      schema,
      formData
    );
    if (ValidationErrors) {
      return setErrors(ValidationErrors);
    }
    dispatch.auth.updatePayoutWalletAddress({ data: data!, setOpen });
  };
  return (
    <DialogLayout open={open}>
      <Container>
        <div className='close-icon'>
          <img
            src={assets.closeIcon}
            alt=''
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(false)}
          />
        </div>
        <div className='content'>
          <h1 className='title'>New Wallet Address</h1>
          <div className='input'>
            <InputField
              placeholder='Payout wallet address'
              type='text'
              name='public_address'
              value={formData.public_address}
              onChange={handleChange}
              error={errors?.public_address}
            />
          </div>
          <div className='input'>
            <InputField
              placeholder='New Payout wallet address*'
              type='text'
              name='new_public_address'
              value={formData.new_public_address}
              onChange={handleChange}
              error={errors?.new_public_address}
            />
          </div>
          <div className='btn'>
            <Button
              variant='secondary'
              className='btn-b'
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button variant='primary' onClick={handleSubmit} disabled={loading}>
              Confirm
            </Button>
          </div>
        </div>
      </Container>
    </DialogLayout>
  );
};

export default UpdateWallet;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 496px;
  & .close-icon {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  & .content {
    margin-top: 6px;
    padding: 40px 30px;
    background-color: #121212;

    & h1 {
      margin-bottom: 40px;
    }

    & .input {
      margin-bottom: 50px;
    }

    & .btn {
      display: flex;
      justify-content: center;
      grid-gap: 20px;
      & .btn-b::before {
        background-color: #121212;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    min-width: 200px;
    width: 100%;
    max-width: 100%;
  }
  @media only screen and (max-width: 350px) {
    padding: 0;
    & .content {
      width: 100%;
    }
    & .btn {
      grid-gap: 10px !important;
      & button {
        padding: 10px 20px;
        font-size: 10px;
        & span {
          font-size: 10px;
        }
      }
    }
  }
`;
