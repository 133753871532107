const theme = {
  palette: {
    primary: '#151df4',
    primaryDark: '#0000b8',
    secondary: '#ff0002',
    anchor: '#0a8df5',
    black: '#000000',
  },
};

export default theme;
