import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../store';

import styled from 'styled-components';
import DialogLayout from './Dialog';
import InputField from '../components/InputField/InputField';
import Checkbox from '../components/Checkbox/Checkbox';
import Button from '../components/Button/Button';

import assets from '../assets';
import theme from '../theme';

const ExchangeDialog: FC = () => {
  const open = useSelector((state: RootState) => state.dialogs.exchange);
  const dispatch = useDispatch<Dispatch>();
  return (
    <DialogLayout open={open}>
      <Container>
        <h1 className='title'>Transfer STZ</h1>
        <div className='exchange'>
          <div className='exchange-container'>
            <div className='row'>
              <img src={assets.stzIcon} alt='' />
              <p className='font-size-16'>STZ</p>
            </div>
            <div className='input'>
              <input type='number' className='font-size-16' min={0} />
              <span className='font-size-10'>~ 889.56 $</span>
            </div>
          </div>
          <p className='font-size-12'>Max</p>
        </div>
        <p className='font-size-10'>889.56 STZ available to transfer</p>
        <InputField
          style={{ background: 'transparent' }}
          placeholder='Payout wallet'
          type='text'
          className='payout'
        />

        <p className='font-size-12'>
          Gas fee would be borne by user during transfer to admin vault (and
          claiming earning from the game if not airdropped) and then by admin
          during payout
        </p>

        <p className='font-size-12 my'>
          To get your share of the earnings from games, transfer all your
          earnings (NFTs & Fungible tokens), and STZ to admin vault after
          connecting your scholar wallet to the 99Starz platform
        </p>

        <p className='font-size-12 mb'>
          After that, your earnings (as per your share) and STZ will be sent to
          you by admin
        </p>

        <PrivacyPolicy>
          <Checkbox className='checkbox' />
          <p className='font-size-12'>
            By creating an account you agree to the
            <Link className='anchor' to='#'>
              &nbsp;terms of use &nbsp;
            </Link>
            and our
            <Link className='anchor' to='#'>
              &nbsp;privacy policy&nbsp;
            </Link>
            .
          </p>
        </PrivacyPolicy>
        <ButtonContainer>
          <Button
            variant='secondary'
            className='btn'
            onClick={() => dispatch.dialogs.closeDialog('exchange')}
          >
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => dispatch.dialogs.openDialog('transfer')}
          >
            Continue
          </Button>
        </ButtonContainer>
      </Container>
    </DialogLayout>
  );
};

export default ExchangeDialog;

const Container = styled.div`
  width: 420px;
  padding: 40px 30px;
  background: #121212;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
  & h1 {
    margin-bottom: 40px;
  }

  & .exchange {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    & p.font-size-12 {
      color: ${theme.palette.anchor};
    }
    & .exchange-container {
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #ffffff33;
      border-radius: 4px;
      & .row {
        display: flex;
        flex: 1;
        align-items: center;
        height: 100%;
        grid-gap: 10px;
        padding-inline: 10px;
      }
      & .input {
        flex: 1;
        height: 58px;
        position: relative;
        & input {
          height: 100%;
          width: calc(100% + 1px);
          outline: none;
          border: none;
          border: 1px solid transparent;
          border-left-color: #ffffff33;
          background-color: transparent;
          color: #ffffff;
          padding-inline: 15px;
          &:focus {
            border: 1px solid #ffffff;
            border-radius: 0px 4px 4px 0px;
          }
          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
        & span {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          opacity: 0.4;
        }
      }
    }
  }
  & p.font-size-10 {
    opacity: 0.4;
    margin-top: 6px;
  }
  & p.my {
    margin-block: 20px;
  }
  & p.mb {
    margin-bottom: 30px;
  }
  & .payout {
    margin-block: 20px 24px;
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
  @media only screen and (max-width: 400px) {
    padding: 30px 20px;
  }
`;

const PrivacyPolicy = styled.div`
  display: flex;
  justify-content: space-between;
  & .checkbox {
    margin-top: 4px;
    margin-right: 8px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  margin-top: 39px;
  & .btn::before {
    background-color: #0e0e0e;
  }
`;
