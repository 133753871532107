import React, { FC, SetStateAction, useEffect, useState } from "react";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../store";
import axios from "axios";

import DialogLayout from "./Dialog";
import Button from "../components/Button/Button";

import { badgeImagesByName } from "../app.config";

import assets from "../assets";
import { IBadge } from "../types/interfaces";
import { applyScholarship } from "../http";

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}

const ApplyScholarshipDialog: FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();

  const user = useSelector((state: RootState) => state.auth.user);
  const [maxBadge, setMaxBadge] = useState<IBadge | null>(null);

  const badges = useSelector((state: RootState) => state.badges.badges);

  const mintedBadges = useSelector(
    (state: RootState) => state.badges.mintedBadges
  );

  const game = useSelector((state: RootState) => state.games.selectedGame);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    title: "",
    about: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.currentTarget;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleApply = async () => {
    try {
      setLoading(true);
      if (game && maxBadge) {
        const apiData = {
          ...data,
          game_id: game.id,
          badge_id: maxBadge.id,
        };

        await applyScholarship(apiData);
        setOpen(false);
        dispatch.feedbackModal.open({
          title: "Success",
          subtitle:
            "You have successfully applied for scholarship. Please wait for admin to send you some assets.",
        });
      }
    } catch (err: any) {
      setOpen(false);
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data.message;
        dispatch.feedbackModal.open({
          title: "Error",
          subtitle: errorMessage,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const getMaxBadge = () => {
    let maxBadge = mintedBadges[0];
    mintedBadges.forEach((badge) => {
      if (maxBadge.badge_type < badge.badge_type) {
        maxBadge = badge;
      }
    });

    const _maxBadge = badges.find(
      (badge) => badge.badge_type === maxBadge.badge_type
    )!;
    setMaxBadge(_maxBadge);
  };

  useEffect(() => {
    if (user) {
      dispatch.badges.getMintedBadges(user.id);
    }
  }, []);

  useEffect(() => {
    if (mintedBadges.length) {
      getMaxBadge();
    }
  }, [mintedBadges]);
  return (
    <DialogLayout open={open}>
      <Container>
        <div className="close-icon-container">
          <img src={assets.closeIcon} alt="" onClick={() => setOpen(false)} />
        </div>
        <Content>
          <div>
            <img src={game?.logo} className="game-icon" alt="" />
          </div>

          <div className="row requirement">
            <h1 className="title font-size-18 mb">Apply for a Scholarship</h1>
            <img
              src={
                maxBadge ? badgeImagesByName[maxBadge?.name] : assets.goldBadge
              }
              className="badge"
              alt=""
            />
            <p className="font-size-8">required</p>
          </div>
          <ApplyDetails>
            <input
              type="text"
              placeholder="Enter your title"
              className="title font-size-24"
              value={data.title}
              name="title"
              onChange={handleChange}
            />
            <div className="divider"></div>
            <textarea
              className="font-size-14"
              placeholder="Tell Me About Yourself!"
              value={data.about}
              name="about"
              onChange={handleChange}
            ></textarea>
          </ApplyDetails>

          <h1 className="font-size-18 title nmb">Have any questions?</h1>
          <p className="font-size-14" style={{ marginTop: "16px" }}>
            Contact 99Starz Captains on Discord!
          </p>

          <CaptainRow>
            {/* <a href='https://99starz.io/' target='_blank' rel='noreferrer'> */}
            <div className="row">
              <img
                src="https://99starz.io/wp-content/uploads/2021/09/team_Vlad-P-240x240.jpg"
                alt=""
              />
              <div>
                <p className="font-size-14">99Starz squarrre#0147</p>
                <p className="font-size-8" style={{ opacity: "0.4" }}>
                  99Starz Captain
                </p>
              </div>
            </div>
            {/* </a> */}
            <p className="font-size-12 title" style={{ margin: "0px" }}>
              or
            </p>

            {/* <a href='https://99starz.io/' target='_blank' rel='noreferrer'> */}
            <div className="row">
              <img
                src="https://99starz.io/wp-content/uploads/2021/09/team_Robert-240x240.png "
                alt=""
              />
              <div>
                <p className="font-size-14">robertih#5300</p>
                <p className="font-size-8" style={{ opacity: "0.4" }}>
                  99Starz Captain
                </p>
              </div>
            </div>
            {/* </a> */}
          </CaptainRow>
          <ButtonContainer>
            <Button variant="secondary" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleApply} disabled={loading}>
              Apply
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default ApplyScholarshipDialog;

const Container = styled.div`
  max-width: 600px;
  /* max-height: 90vh; */
  width: 100%;

  & h1.nmb {
    margin: 0;
  }

  & .close-icon-container {
    display: flex;
    justify-content: flex-end;
    padding: 6px;
    & img {
      cursor: pointer;
    }
  }
`;

const Content = styled.div`
  background-color: #121212;
  padding-block: 50px 40px;
  padding-inline: 30px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;

  & .game-icon {
    max-width: 120px;
    min-width: 57px;
  }

  & .row {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-direction: column;

    &.requirement {
      margin-block: 20px 30px;
    }

    & img.badge {
      width: 24px;
    }
  }

  @media only screen and (max-width: 700px) {
    padding-block: 25px 20px;
    padding-inline: 15px;
  }
`;

const ApplyDetails = styled.div`
  width: 100%;
  background: #000000;
  border-radius: 4px;
  padding: 24px 30px;
  margin-bottom: 40px;

  & h1.mb {
    padding-inline: 10px;
    margin-bottom: 20px;
  }

  & .divider {
    border-top: 1px solid #ffffff33;
    padding-bottom: 20px;
  }

  & input {
    width: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    color: #ffffff;

    margin-block: 20px;
  }

  & textarea {
    width: 100%;
    height: 150px;
    background-color: transparent;
    border: none;
    outline: none;
    resize: none;
  }
`;

const CaptainRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-block: 30px 40px;

  & a {
    text-decoration: none;
    text-transform: none;
  }
  & .row {
    gap: 10px;

    & img {
      height: 48px;
      width: 48px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
