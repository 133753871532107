import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const RedirectOutlet = () => {
  // redirect user to dashboard if user is loggedin
  // and public routes are accessed
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  // added this check for preventing redirection if user is setting up profile and accidentally refresh the page
  if (isLoggedIn && window.location.pathname !== '/profile-setup')
    return <Navigate to='/dashboard' replace={true} />;
  return <Outlet />;
};

export default RedirectOutlet;
