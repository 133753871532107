import React, { FC, useEffect, useState } from 'react'; // useState

import styled from 'styled-components';
import classNames from 'classnames';

import { IScreenshot } from '../../types/interfaces';

// import { config } from 'react-spring';

// import Carousel from 'react-spring-3d-carousel';

// import assets from '../../assets';
// import classNames from 'classnames';

interface IProps {
  screenshots: IScreenshot[];
}

const ScreenshotSlider: FC<IProps> = ({ screenshots }) => {
  const [next, setNext] = useState(0);

  const [prev, setPrev] = useState(0);

  const [nextToNext, setNextToNext] = useState(0);
  const [prevToPrev, setPrevToPrev] = useState(0);
  const slides = screenshots.map(screenshot => screenshot.screenshot_url);
  const [active, setActive] = useState('');

  // const [state, setState] = useState({
  //   goToSlide: 0,
  //   offsetRadius: 4,
  //   showNavigation: false,
  //   config: config.slow,
  // });

  const handleGetIndexes = () => {
    const array = [...slides];

    let _prev: number;
    let _prevToPrev: number;
    let _next: number;
    let _nextToNext: number;

    if (array.indexOf(active) === 0) {
      _prev = array.indexOf(array.at(-1)!);
    } else if (array.indexOf(active) > 0) {
      _prev = array.indexOf(active) - 1!;
    }

    if (array.indexOf(active) === 0) {
      _prevToPrev = array.indexOf(array.at(-2)!);
    } else {
      if (array.indexOf(active) - 1 === 0) {
        _prevToPrev = array.indexOf(array.at(-1)!);
      } else {
        if (array.indexOf(active) - 1 !== 0) {
          if (array.indexOf(active) - 2 !== 0) {
            _prevToPrev = array.indexOf(active) - 2!;
          } else {
            _prevToPrev = array.indexOf(array.at(-1)!);
          }
        } else {
          _prevToPrev = array.indexOf(array.at(-2)!);
        }
      }
    }

    if (array.indexOf(active) + 1 !== array.length) {
      _next = array.indexOf(active) + 1;
    } else {
      _next = 0;
    }

    if (array.indexOf(active) + 1 !== array.length) {
      if (array.indexOf(active) + 2 !== array.length) {
        _nextToNext = array.indexOf(active) + 2!;
      } else {
        _nextToNext = 0;
      }
    } else {
      _nextToNext = array.indexOf(array.at(1)!);
    }

    // @ts-ignore
    setPrev(_prev);
    setPrevToPrev(_prevToPrev);
    setNext(_next);
    setNextToNext(_nextToNext);
    // @ts-ignore
    // console.log(_prevToPrev, _prev, array.indexOf(active), _next, _nextToNext);
  };

  useEffect(() => {
    setActive(slides[0]);
  }, []);

  useEffect(() => {
    // const active = Math.floor(Math.random() * 8);
    handleGetIndexes();
  }, [active]);

  return (
    <>
      <Container>
        <section id='slider'>
          {slides.map((slide, index) => (
            <input
              type='radio'
              name='slider'
              readOnly
              checked={slides.indexOf(active) === index}
              id={`s${index + 1}`}
              key={index}
              onChange={() => setActive(slides[index])}
            />
          ))}

          {slides.map((slide, index) => (
            <label
              key={index}
              htmlFor={`s${index + 1}`}
              id={`slide${index + 1}`}
              className={classNames(
                'label',
                index === slides.indexOf(active) && 'active',
                index === prev && 'prev',
                index === prevToPrev && 'prevToPrev',
                index === next && 'next',
                index === nextToNext && 'nextToNext'
              )}
            >
              <img src={slide} alt='' />
            </label>
          ))}
        </section>
      </Container>
      {/* <Carousel
          slides={slides.map((slide, index) => {
            return {
              ...slide,
              onClick: () => setState(prev => ({ ...prev, goToSlide: index })),
            };
          })}
          goToSlide={state.goToSlide}
          offsetRadius={state.offsetRadius}
          showNavigation={state.showNavigation}
          animationConfig={state.config}
        /> */}

      {/* <Pagination>
        {slides.map((slide, index) => (
          <div
            onClick={() => setState(prev => ({ ...prev, goToSlide: index }))}
            className={classNames(
              'pagination',
              state.goToSlide === index && 'active'
            )}
          ></div>
        ))}
      </Pagination> */}
    </>
  );
};

export default ScreenshotSlider;

const Container = styled.div`
  width: 100%;
  height: 500px;
  margin: 0 auto;

  @media only screen and (min-width: 1441px) {
    width: 95%;
  }

  #slider {
    position: relative;
    width: 80%;
    height: 32vw;
    margin: 20px auto;
    font-family: 'Helvetica Neue', sans-serif;
    perspective: 1400px;
    transform-style: preserve-3d;
  }

  input[type='radio'] {
    position: relative;
    top: 108%;
    left: 50%;
    width: 18px;
    height: 18px;
    margin: 0 15px 0 0;
    transform: translateX(-83px);
    cursor: pointer;

    appearance: none;
    -webkit-appearance: none;
    height: 4px;
    width: 4px;
    background-color: #ffffff99;
    border-radius: 2px;
    transition: 0.4s ease;
    &:last-of-type {
      margin-right: 0px;
    }
    &:checked {
      width: 40px;
      background-color: #ffffff;
    }
  }

  label {
    position: absolute;
    width: 100%;
    height: 100%;
    color: white;
    font-size: 70px;
    font-weight: bold;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 400ms ease;

    &.active {
      box-shadow: 0 13px 26px rgba(0, 0, 0, 0.3), 0 12px 6px rgba(0, 0, 0, 0.2);
      transform: translate3d(0%, 0, 0px);
    }

    &.prev {
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
      transform: translate3d(-20%, 0, -200px);
    }

    &.prevToPrev {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
      transform: translate3d(-35%, 0, -500px);
    }

    &.next {
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
      transform: translate3d(20%, 0, -200px);
    }

    &.nextToNext {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
      transform: translate3d(35%, 0, -450px);
    }

    & img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      color: white;
      font-size: 70px;
      font-weight: bold;
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 400ms ease;
    }
  }

  /* Slider Functionality */

  /* Active Slide */
  /* #s1:checked ~ #slide1,
  #s2:checked ~ #slide2,
  #s3:checked ~ #slide3,
  #s4:checked ~ #slide4,
  #s5:checked ~ #slide5 {
    box-shadow: 0 13px 26px rgba(0, 0, 0, 0.3), 0 12px 6px rgba(0, 0, 0, 0.2);
    transform: translate3d(0%, 0, 0px);
  }

  /* Next Slide */
  /* #s1:checked ~ #slide2,
  #s2:checked ~ #slide3,
  #s3:checked ~ #slide4,
  #s4:checked ~ #slide5,
  #s5:checked ~ #slide1 {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
    transform: translate3d(20%, 0, -100px);
  } */

  /* Next to Next Slide */
  /* #s1:checked ~ #slide3,
  #s2:checked ~ #slide4,
  #s3:checked ~ #slide5,
  #s4:checked ~ #slide1,
  #s5:checked ~ #slide2 {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    transform: translate3d(40%, 0, -250px);
  } */

  /* Previous to Previous Slide */
  /* #s1:checked ~ #slide4,
  #s2:checked ~ #slide5,
  #s3:checked ~ #slide1,
  #s4:checked ~ #slide2,
  #s5:checked ~ #slide3 {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    transform: translate3d(-40%, 0, -250px);
  } */

  /* Previous Slide */
  /* #s1:checked ~ #slide5,
  #s2:checked ~ #slide1,
  #s3:checked ~ #slide2,
  #s4:checked ~ #slide3,
  #s5:checked ~ #slide4 {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
    transform: translate3d(-20%, 0, -100px);
  }  */
`;

// const Pagination = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 16px;

//   & > div {
//     height: 4px;
//     width: 4px;
//     background-color: #ffffff66;
//     border-radius: 2px;
//     cursor: pointer;
//     transition: 0.4s ease;

//     &.active {
//       width: 40px;
//       background-color: #ffffff;
//     }
//   }
// `;
