import React, { FC, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { NotificationTypes } from '../../types/enums';

import assets from '../../assets';
import { INotification } from '../../types/interfaces';

dayjs.extend(utc);

const Notifications: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications
  );

  const getFormatedTime = (time: string) => {
    let minutes = dayjs().utc().diff(dayjs(time).utc(), 'm');
    let hours, days;

    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    days = Math.floor(hours / 24);
    hours = hours % 24;

    if (days) {
      return `${days}d, ${hours}h and ${minutes}m ago`;
    }

    if (hours) {
      return `${hours}h and ${minutes}m ago`;
    }

    return `${minutes}m ago`;
  };

  const handleRenderNotifications = (notification: INotification) => {
    if (notification.type === NotificationTypes.SCHOLARSHIP) {
      return (
        <Notification>
          <div className='image'>
            <img src={notification.image_url || assets.blank} alt='' />
            {/* don't render this if offline user */}
            {/* <div className='dot'></div> */}
          </div>
          <div>
            <p className='font-size-14'>
              <b>You</b> applied for scholarship in <b>{notification.name}</b>.
            </p>

            <p className='font-size-10' style={{ opacity: '0.4' }}>
              {getFormatedTime(notification.created_at)}
            </p>
          </div>
        </Notification>
      );
    } else if (notification.type === NotificationTypes.ASSET_TRANSFER) {
      return (
        <Notification key={notification.id}>
          <div className='image'>
            <img src={notification.image_url || assets.blank} alt='' />
            {/* don't render this if offline user */}
            {/* <div className='dot'></div> */}
          </div>
          <div>
            <p className='font-size-14'>
              Asset <b>({notification.asset_id})</b> by{' '}
              <b>{notification.name}</b> was sent to{' '}
              <b>{notification.nick_name}</b>.
            </p>
            <p className='font-size-10' style={{ opacity: '0.4' }}>
              {getFormatedTime(notification.created_at)}
            </p>
          </div>
        </Notification>
      );
    } else if (notification.type === NotificationTypes.OTHERGUILDSCHOLARS) {
      return (
        <Notification key={notification.id}>
          <div className='image'>
            <img src={notification.logo} alt='' />
            {/* don't render this if offline user */}
            {/* <div className='dot'></div> */}
          </div>
          <div>
            <p className='font-size-14'>
              <b>You</b> applied for other guild captain in{' '}
              <b>{notification.name}</b>.
            </p>

            <p className='font-size-10' style={{ opacity: '0.4' }}>
              {getFormatedTime(notification.created_at)}
            </p>
          </div>
        </Notification>
      );
    }
  };
  useEffect(() => {
    dispatch.notifications.getAllNotifications(null);
  }, []);
  return (
    <NotificationMenu>
      <p className='font-size-12 title mb'>Notification</p>
      <Divider />
      {/* <div className='unread'>
        <Notification>
          <Image>
            <img
              src={user?.image_url || assets.user}
              style={{ borderRadius: '50%' }}
              alt=''
            />
            {/* render below if unread msg */}
      {/* <div className='dot'></div>
          </Image>
          <div className='notification-body'>
            <p className='font-size-12 notification-title'>
              You earned <b>16 STZ</b> on <b>Benji Bananas</b>.
            </p>
            <p className='font-size-8' style={{ opacity: '0.4' }}>
              1m ago
            </p>
          </div>
        </Notification>
      </div> */}
      {/* <Divider /> */}
      {notifications.map((notification, i) => {
        return handleRenderNotifications(notification);
      })}
      <Divider />
      <p className='font-size-12 title btn'>Show All Notifications</p>
    </NotificationMenu>
  );
};

export default Notifications;

const NotificationMenu = styled.div`
  position: absolute;
  width: 288px;
  background-color: #121212;
  right: 56px;
  top: 58px;
  z-index: 100;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 16px;
  max-height: 400px;
  overflow-y: auto;

  & p.mb {
    margin-bottom: 12px;
  }

  & .unread {
    padding-block: 4px;
  }

  & p.btn {
    margin-top: 14px;
    text-align: center;
    cursor: pointer;
    opacity: 0.4;
  }
`;

const Divider = styled.div`
  border-top: 1px solid #ffffff33;
`;

const Notification = styled.div`
  display: flex;
  gap: 8px;
  padding-block: 12px;
  padding-inline: 8px;
  align-items: flex-start;
  & img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
  }
  & .notification-body {
    & .notification-title {
      max-width: 25ch;
    }
  }
`;

// const Image = styled.div`
//   position: relative;
//   & img {
//     width: 36px;
//     height: 36px;
//     object-fit: cover;
//   }

//   & .dot {
//     width: 8px;
//     height: 8px;
//     background-color: ${theme.palette.primary};
//     border-radius: 50%;
//     position: absolute;
//     top: 0px;
//     right: 0px;
//   }
// `;
