import React, { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import DashboardLayout from '../layouts/DashboardLayout';
import ConfirmAccount from '../pages/ConfirmAccount/ConfirmAccount';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import Login from '../pages/Login/Login';
import PasswordReset from '../pages/PasswordReset/PasswordReset';
import SignUp from '../pages/SignUp/SignUp';
import Home from '../pages/Home/Home';
import PrivateOutlet from './PrivateOutlet/PrivateOutlet';
import GameSelection from '../pages/GameSelection/GameSelection';
import BadgeSelection from '../pages/BadgeSelection/BadgeSelection';
import MyBadge from '../pages/MyBadge/MyBadge';
// import MyAssets from '../pages/MyAssets/MyAssets';
import MyEarnings from '../pages/MyEarnings/MyEarnings';
import RedirectOutlet from './RedirectOutlet/RedirectOutlet';
import ProfileSetup from '../pages/ProfileSetup/ProfileSetup';
import Games from '../pages/Games/Games';
import GameDetails from '../pages/GameDetails/GameDetails';
import Vault from '../pages/Vault/Vault';
import Performance from '../pages/Performance/Performance';
import UserProfile from '../pages/UserProfile/UserProfile';

const Index: FC = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path='/' element={<RedirectOutlet />}>
        <Route path='' element={<Navigate to='/login' replace={true} />} />
        <Route path='login' element={<Login />} />
        <Route path='signup' element={<SignUp />} />
        <Route path='account-confirmation' element={<ConfirmAccount />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='reset-password' element={<PasswordReset />} />
        <Route path='profile-setup' element={<ProfileSetup />} />
      </Route>

      {/* Private Routes */}
      <Route path='/' element={<PrivateOutlet />}>
        <Route path='/dashboard' element={<DashboardLayout />}>
          <Route path='' element={<Navigate to='/dashboard/profile' />} />
          {/* <Route path='assets' element={<MyAssets />} /> */}
          <Route path='earnings' element={<MyEarnings />} />
          <Route path='select-game' element={<GameSelection />} />
          <Route path='select-badge' element={<BadgeSelection />} />
          <Route path='mint-badge' element={<Home />} />
          <Route path='my-badge' element={<MyBadge />} />
          <Route path='games' element={<Games />} />
          <Route path='games/:gameId' element={<GameDetails />} />
          <Route path='vault' element={<Vault />} />
          <Route path='performance' element={<Performance />} />
          <Route path='profile' element={<UserProfile />} />
        </Route>
      </Route>
      <Route path='*' element={<Navigate to='/dashboard/games' />} />
    </Routes>
  );
};

export default Index;
