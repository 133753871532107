import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  getGames,
  scholarGames,
  // getBalanceInGame,
  getGameNftContracts,
  getGamesWithContracts,
} from '../../http';
import axios from 'axios';

import {
  IGameModal,
  IGame,
  IGameWithContracts,
  IFilterGame,
  IGameDetails,
  IScholarGame,
} from '../../types/interfaces';

export const games = createModel<RootModel>()({
  name: 'games',
  state: {
    selected: null,
    games: [],
    loading: false,
    error: '',
    scholarGames: [],
    tokenBalances: {},
    gamesWithContracts: [],
    goldBadgeGames: [],
    silverBadgeGames: [],
    bronzeBadgeGames: [],
    newGames: [],
    selectedGame: null,
  } as IGameModal,
  reducers: {
    setGames(state, payload: Array<IGame>) {
      state.games = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setError(state, payload: string) {
      state.error = payload;
    },
    setSelectedGame(state, payload: IGame) {
      state.selected = payload;
    },
    setSelected(state, payload: IGameDetails) {
      state.selectedGame = payload;
    },
    setScholarGames(state, payload: Array<IScholarGame>) {
      state.scholarGames = payload;
    },
    setTokenBalances(state, payload) {
      state.tokenBalances = payload;
    },
    setGamesWithContracts(state, payload: Array<IGameWithContracts>) {
      state.gamesWithContracts = payload;
    },
    setGoldBadgeGames(state, payload: Array<IFilterGame>) {
      state.goldBadgeGames = payload;
    },
    setSilverBadgeGames(state, payload: Array<IFilterGame>) {
      state.silverBadgeGames = payload;
    },
    setBronzeBadgeGames(state, payload: Array<IFilterGame>) {
      state.bronzeBadgeGames = payload;
    },
    setNewGames(state, payload: Array<IFilterGame>) {
      state.newGames = payload;
    },
  },
  effects: dispatch => ({
    async getAllGames() {
      try {
        dispatch.games.setLoading(true);
        const { data } = await getGames();

        // games with all nft contracts
        const games = await Promise.all(
          data.map(async (gameItem: IFilterGame) => ({
            ...gameItem,
            nft_contracts: (await getGameNftContracts(gameItem.id)).data,
          }))
        );

        dispatch.games.setGames(games);
      } catch (err) {
        dispatch.games.setError('Something went wrong!');
      } finally {
        dispatch.games.setLoading(false);
      }
    },
    async getScholarGames(payload: string) {
      try {
        dispatch.games.setLoading(true);
        const { data: games } = await scholarGames(payload);
        // const data = await Promise.all(
        //   games.map(async gameItem => ({
        //     ...gameItem,
        //     nft_contracts: (await getGameNftContracts(gameItem.id)).data,
        //   }))
        // );
        dispatch.games.setScholarGames(games);
        // const balances = (data as Array<IGame>).map(async game => {
        //   return await getBalanceInGame(
        //     game.id,
        //     state.auth.walletAccounts[0].starz_wallet_address
        //   );
        // });
        // const _balances = await Promise.all(balances);
        // const _tokenBalances: { [x: string]: any } = {};
        // _balances.forEach(({ data }) => {
        //   if (_tokenBalances.hasOwnProperty(data.symbol)) {
        //     _tokenBalances[data.symbol] =
        //       _tokenBalances[data.symbol].balance + data.balance;
        //   } else {
        //     _tokenBalances[data.symbol] = {
        //       balance: data.balance,
        //       name: data.name,
        //       logo: data.logo,
        //     };
        //   }
        // });
        // dispatch.games.setTokenBalances(_tokenBalances);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.games.setLoading(false);
      }
    },
    async gamesWithContracts() {
      try {
        dispatch.games.setLoading(true);
        const { data } = await getGamesWithContracts();
        dispatch.games.setGamesWithContracts(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.games.setLoading(false);
      }
    },
    async getAllGamesCategory() {
      try {
        dispatch.games.setLoading(true);

        axios
          .all([getGames('2'), getGames('1'), getGames('0'), getGames('new')])
          .then(
            axios.spread((res1, res2, res3, res4) => {
              dispatch.games.setGoldBadgeGames(res1.data);
              dispatch.games.setSilverBadgeGames(res2.data);
              dispatch.games.setBronzeBadgeGames(res3.data);
              dispatch.games.setNewGames(res4.data);
            })
          )
          .catch(err => console.log(err.message));
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.games.setLoading(false);
      }
    },
  }),
});
