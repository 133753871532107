import { createModel } from '@rematch/core';
import React from 'react';
import { RootModel } from '.';
import { getAllNfts } from '../../http';

interface IState {
  loading: boolean;
  nfts: Array<any>;
}

interface IGetNftPayload {
  data: {
    contract_id: string;
  };
  cursor?: string;
  setCursor: React.Dispatch<React.SetStateAction<string | null>>;
}

export const vault = createModel<RootModel>()({
  name: 'vault',
  state: {
    loading: false,
    nfts: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setNfts(state, payload: Array<any>) {
      state.nfts = payload;
    },
  },
  effects: dispatch => ({
    async getNfts(payload: IGetNftPayload) {
      try {
        dispatch.vault.setLoading(true);
        const { data } = await getAllNfts(payload.data, payload?.cursor);
        payload.setCursor(data.cursor);
        dispatch.vault.setNfts(data.result);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.vault.setLoading(false);
      }
    },
  }),
});
