import assets from './assets';

export const appEnv = process.env.REACT_APP_ENV;

export const apiUrl = process.env.REACT_APP_API_URL;

export const resizerUrl = process.env.REACT_APP_IMAGE_RESIZER;

export const stzContractAddress = process.env.REACT_APP_STZ_CONTRACT_ADDRESS;

const infuraId = process.env.REACT_APP_INFURA_ID;

export const isMainnet = process.env.REACT_APP_IS_MAINNET;

export const rinkebyRpc = `https://rinkeby.infura.io/v3/${infuraId}`;

export const mainnetRpc = `https://mainnet.infura.io/v3/${infuraId}`;

export const badgeImagesByName: { [x: string]: any } = {
  'Gold Badge': assets.goldBadge,
  'Silver Badge': assets.silverBadge,
  'Bronze Badge': assets.bronzeBadge,
};
