import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { getScholarAssets } from '../../http';
import { IGetScholarAssets, INft } from '../../types/interfaces';

interface IState {
  nfts: Array<INft>;
  loading: boolean;
}

export const nfts = createModel<RootModel>()({
  name: 'nfts',
  state: {
    nfts: [],
    loading: false,
  } as IState,
  reducers: {
    setNfts(state, payload: Array<INft>) {
      state.nfts = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
  },
  effects: dispatch => ({
    async getScholarNfts(payload: IGetScholarAssets) {
      try {
        dispatch.nfts.setLoading(true);
        const { data } = await getScholarAssets(payload);
        const scholarNfts: INft[] = [];
        data.forEach(nfts => nfts.Nfts.forEach(nft => scholarNfts.push(nft)));
        dispatch.nfts.setNfts(scholarNfts);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.nfts.setLoading(false);
      }
    },
  }),
});
