import React, { FC, useState, useEffect } from 'react';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../store';

import ClickAwayListener from 'react-click-away-listener';
import classNames from 'classnames';
import styled from 'styled-components';
import assets from '../assets';
import theme from '../theme';

import Notifications from '../components/Notifications/Notifications';
// eslint-disable-next-line
import { isMainnet } from '../app.config';

import { INavItem } from '../types/interfaces';
import Dialogs from '../dialogs';
import Footer from '../components/Footer/Footer';
import Button from '../components/Button/Button';
import WalletConnections from '../dialogs/WalletConnection';

const initialNavItems: Array<INavItem> = [
  {
    title: 'Home',
    img: assets.home,
    to: '/dashboard/profile',
    hideIfNoAcc: false,
    hideIfOtherGuild: false,
  },
  {
    title: 'Games',
    img: assets.gamesIcon,
    to: '/dashboard/games',
    hideIfNoAcc: true,
    hideIfOtherGuild: true,
  },
  // {
  //   title: 'My Earnings',
  //   img: assets.earnings,
  //   to: '/dashboard/earnings',
  //   hideIfNoAcc: true,
  // },
  {
    title: 'Performance',
    img: assets.performance,
    to: '/dashboard/performance',
    hideIfNoAcc: true,
    hideIfOtherGuild: false,
  },
  // {
  //   title: 'My Assets',
  //   img: assets.asset,
  //   to: '/dashboard/assets',
  //   hideIfNoAcc: true,
  // },
  // {
  //   title: 'Voting',
  //   img: assets.voting,
  //   to: '/dashboard/voting',
  //   hideIfNoAcc: true,
  // },
  {
    title: 'Vault',
    img: assets.vault,
    to: '/dashboard/vault',
    hideIfNoAcc: true,
    hideIfOtherGuild: true,
  },
];

const DashboardLayout: FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationMenu, setNotificationMenu] = useState(false);
  const [walletMenu, setWalletMenu] = useState(false);
  const [walletDialog, setWalletDialog] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const sideBar = useSelector((state: RootState) => state.sideBar.open);
  const user = useSelector((state: RootState) => state.auth.user);
  const walletAddress = useSelector(
    (state: RootState) => state.auth.walletAddress
  );

  const starz_wallet_address = useSelector(
    (state: RootState) => state.auth.walletAccounts[0]?.starz_wallet_address
  );

  let navItems = initialNavItems;
  if (!starz_wallet_address) {
    navItems = initialNavItems.filter(
      item => item.hideIfNoAcc === !!starz_wallet_address
    );
  }

  if (user?.origin === 'otherguild') {
    navItems = initialNavItems.filter(item => item.hideIfOtherGuild !== true);
  }

  const [showToolip, setShowTooltip] = useState(false);
  const [menuTooltip, setMenuTooltip] = useState(false);

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(starz_wallet_address);
  };

  const handleLogout = () => {
    dispatch.auth.logOut();
    navigate('/', { replace: true });
  };

  const truncateString = (value: string): string => {
    const start = value.slice(0, 4);
    const end = value.slice(value.length - 4, value.length);
    return `${start}...${end}`;
  };

  const handleConnectWallet = async () => {
    if ((window as any).ethereum) {
      const accounts = await (window as any).ethereum.request({
        method: 'eth_requestAccounts',
      });
      dispatch.auth.connectWallet({
        public_address: accounts[0],
        setWalletDialog,
      });
    } else {
      console.log('Need to install Meta Mask');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowTooltip(false);
      setMenuTooltip(false);
    }, 700);
  }, [showToolip, menuTooltip]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 700) {
        dispatch.sideBar.sideBarHidden(true);
      }
      if (window.innerWidth >= 700) {
        dispatch.sideBar.sideBarHidden(false);
      }
    });
  });

  return (
    <Container>
      <Topbar className={classNames(sideBar ? 'm-lg' : 'm-sm')}>
        <div className='top'>
          <div>
            <img src={assets.brandLogo} className='logo' alt='' />
          </div>
          {/* TODO: Fix centering this later */}
          {walletAddress ? (
            <>
              {/* <div className='mid'>
              <img src={assets.walllet} alt='' />
              <p className='wallet-connection bg-grey'>
                {truncateString(walletAddress)}
              </p>
              <img
                src={assets.copyIcon}
                onClick={() => {
                  handleCopyAddress();
                  setShowTooltip(true);
                }}
                className='copy-icon'
                alt=''
              />
              <p className={classNames('tooltip', showToolip && 'show')}>
                Copied!
              </p>
            </div> */}
            </>
          ) : (
            <div className='mid flex-col'>
              <div className='wrapper'>
                <img src={assets.walllet} alt='' />
                <ClickAwayListener onClickAway={() => setWalletMenu(false)}>
                  <div style={{ position: 'relative', zIndex: 1 }}>
                    <p
                      className='font-size-12 wallet-connection'
                      style={{ cursor: 'pointer' }}
                      onClick={() => setWalletMenu(!walletMenu)}
                    >
                      Not connected
                    </p>
                    {walletMenu && (
                      <WalletMenu>
                        <div className='row' onClick={handleConnectWallet}>
                          <img src={assets.metamask} alt='' />
                          <span className='font-size-14'>
                            Connect to MetaMask
                          </span>
                        </div>
                        {/* <div className='divider'></div>
                        <div className='row'>
                          <img src={assets.brandLogo} alt='' />
                          <span className='font-size-14'>99Starz</span>
                        </div> */}
                      </WalletMenu>
                    )}
                  </div>
                </ClickAwayListener>
              </div>
              <div className='message'>
                Please connect your metamask wallet first
              </div>
            </div>
          )}
          <div className='right'>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://chrome.google.com/webstore/detail/99starz-wallet/iahlgakbmpphddhphaoakhbcamcibnch'
            >
              <Button
                variant='primary'
                style={{ marginRight: '40px', padding: '8px 35px' }}
                download
              >
                Download 99Starz wallet
              </Button>
            </a>
            <ClickAwayListener onClickAway={() => setNotificationMenu(false)}>
              <NotificationsMenu>
                <img
                  src={assets.notification}
                  style={{ opacity: notificationMenu ? '1' : '1' }}
                  className='notification-icon'
                  alt=''
                  onClick={() => setNotificationMenu(!notificationMenu)}
                />
                {notificationMenu && <Notifications />}
              </NotificationsMenu>
            </ClickAwayListener>

            <div className='user-profile-menu'>
              <Link to='/dashboard/profile'>
                <img
                  src={user?.image_url || assets.blank}
                  className='user-image'
                  alt=''
                />
              </Link>
              <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                <Menu>
                  <div
                    className='row'
                    onClick={() => setMenuOpen(!menuOpen)}
                    style={{ cursor: 'pointer' }}
                  >
                    <p className='font-size-14'>
                      {user?.nick_name || user?.fullname || 'Scholar'}
                    </p>
                    <img src={assets.downArrow} alt='' />
                  </div>

                  {menuOpen && (
                    <div className='menu-popup'>
                      <div className='profile'>
                        <img
                          src={user?.image_url || assets.blank}
                          className='user-image'
                          alt=''
                        />
                        <div className='info'>
                          <p className='font-size-14'>{user?.nick_name}</p>
                          {starz_wallet_address && (
                            <div className='address-container'>
                              <p className='font-size-10'>
                                {truncateString(starz_wallet_address)}
                              </p>
                              <img
                                src={assets.copyIcon}
                                className='menu-copy-icon'
                                alt=''
                                onClick={() => {
                                  handleCopyAddress();
                                  setMenuTooltip(true);
                                }}
                              />
                              <p
                                className={classNames(
                                  'tooltip',
                                  menuTooltip && 'show'
                                )}
                              >
                                Copied!
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <div
                        className='menu-item'
                        onClick={() => setMenuOpen(false)}
                      >
                        <img src={assets.msg} alt='' />
                        <p className='font-size-14'>Settings</p>
                      </div> */}
                      <div
                        className='menu-item'
                        onClick={() => setMenuOpen(false)}
                      >
                        <img src={assets.msg} alt='' />
                        <a href='mailto:dev@99starz.io' className='sub-item'>
                          <p className='font-size-14'>Report a bug</p>
                        </a>
                      </div>
                      <div
                        className='menu-item hidden-mobile'
                        onClick={() => setMenuOpen(false)}
                      >
                        <img src={assets.msg} alt='' />
                        <p className='font-size-14'>Help & Support</p>
                      </div>
                      <div className='menu-item logout' onClick={handleLogout}>
                        <img src={assets.logout} alt='' className='lougtIcon' />
                        <p className='font-size-14'>Logout</p>
                      </div>
                      <div
                        className='menu-item hidden-mobile'
                        onClick={() => setMenuOpen(false)}
                      >
                        <p className='font-size-12 text-center'>
                          Powered by
                          <Link to='#' className='anchor'>
                            99Starz
                          </Link>
                          . v1.03
                        </p>
                      </div>
                    </div>
                  )}
                </Menu>
              </ClickAwayListener>
            </div>
          </div>
        </div>
      </Topbar>
      <SideBar
        className={classNames(sideBar ? 'open' : 'close')}
        onMouseLeave={() => dispatch.sideBar.closeSideBar()}
      >
        <div className='top-section '>
          <img
            src={assets.brandLogo}
            style={{ marginLeft: sideBar ? '24px' : '' }}
            className='brand-logo'
            alt=''
          />
          {/* <img
            src={assets.menuIcon}
            onClick={() => dispatch.sideBar.toggleSideBar()}
            style={{ marginLeft: sideBar ? '42px' : '' }}
            className='menu-icon'
            alt=''
          /> */}
        </div>

        <div className='middle-section'>
          <ul className='nav-links'>
            {navItems.map((item, index) => (
              <NavLink
                end={item.to === '/dashboard/games' ? false : true}
                key={index}
                to={item.to}
                className={navData => (navData.isActive ? 'active' : 'link')}
              >
                <li
                  className={classNames(sideBar ? 'open' : 'close')}
                  // onMouseEnter={() => dispatch.sideBar.openSideBar()}
                >
                  <img src={item.img} alt='' />
                  <p className='font-size-14'>{item.title}</p>
                </li>
              </NavLink>
            ))}
          </ul>
        </div>
        <div className='bottom-section'>
          <ul className='nav-links'>
            {/* <NavLink
              to='/help-and-support'
              className={navData => (navData.isActive ? 'link active' : 'link')}
            >
              <li className={classNames(sideBar ? 'open' : 'close')}>
                <img src={assets.msg} alt='' />
                <p className='font-size-14'>Help & Support</p>
              </li>
            </NavLink> */}
          </ul>
          <div className='divider' />
          <p
            className='font-size-12'
            style={{
              minWidth: '10px',
              width: '100%',
              textAlign: 'center',
              opacity: 0.4,
            }}
          >
            {/* {sideBar ? (
              <>
                Powered by
                <Link to='#' className='anchor'>
                  99Starz
                </Link>
                . v1.03
              </>
            ) : ( */}
            v1.04
            {/* )} */}
          </p>
        </div>
      </SideBar>
      <Content className={classNames(sideBar ? 'open' : 'close')}>
        <BreadCrums
          style={{
            background:
              window.location.pathname === '/dashboard' ? 'transparent' : '',
          }}
        >
          <p
            className={classNames('font-size-10', sideBar ? 'm-lg' : 'm-sm')}
            style={{ opacity: '0.4' }}
          >
            Scholarship Panel | Become a scholar | Fill the Scholarship forms
          </p>
        </BreadCrums>
        <Outlet />
        <Footer />
      </Content>
      <BottomBar>
        {navItems.map((navItem, index) => (
          <NavLink
            style={{ color: 'transparent' }}
            to={navItem.to}
            key={index}
            end={navItem.to === '/dashboard/games' ? false : true}
            className={navData => (navData.isActive ? 'active' : 'link')}
          >
            <div className='tab'>
              <img src={navItem.img} alt='' />
              <p className='tab-label'>{navItem.title}</p>
            </div>
          </NavLink>
        ))}
      </BottomBar>
      {walletDialog && (
        <WalletConnections open={walletDialog} setOpen={setWalletDialog} />
      )}
      <Dialogs />
    </Container>
  );
};

export default DashboardLayout;

const Container = styled.div`
  min-height: 100vh;
  height: 100vh;
  max-height: auto;
`;

const Topbar = styled.div`
  height: 112px;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  transition: 0.3s ease;
  & .logo {
    display: none;
  }
  padding-left: 104px;

  /* &.m-sm {
    padding-left: 8px;
  }
  &.m-lg {
    padding-left: 260px;
  } */
  & .top {
    height: 80px;
    background-color: ${theme.palette.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 32px;
    position: relative;
    & .mid {
      position: absolute;
      /* background-color: red; */
      left: calc(50% - 104px);
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      /* position: relative; */
      /* margin-left: 120px; */
      &.flex-col {
        flex-direction: column;
      }
      & .wrapper {
        display: flex;
        align-items: center;
      }
      & p {
        background-color: #ff000266;
        padding: 4px;
        border-radius: 2px;
        margin-left: 10px;
      }
      & p.bg-grey {
        background-color: #ffffff1a;
        margin-right: 6px;
      }
      & .message {
        margin-top: 4px;
        color: white;
        font-size: 14px;
        opacity: 0.4;
      }
      & .copy-icon {
        cursor: pointer;
      }
      & .tooltip {
        position: absolute;
        left: 105%;
        top: calc(50% - 13px);
        background-color: #ffffff33;
        padding-inline: 5px;
        border-radius: 2px;
        font-size: 12px;
        height: 25px;
        transform: scale(0.1);
        opacity: 0;
        pointer-events: none;
        transition: 0.2s ease;
        &.show {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
    & .right {
      display: flex;
      align-items: center;
      & .user-profile-menu {
        display: flex;
        align-items: center;
      }
      & .notification-icon {
        margin-right: 32px;
      }
      & .user-image {
        height: 48px;
        width: 48px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    &.m-sm {
      padding-left: 0px;
    }
    &.m-lg {
      padding-left: 0px;
    }

    & .top {
      height: 60px;
      padding-inline: 25px;
      justify-content: space-between;
      & .mid {
        margin-left: 0px;
      }
      & .logo {
        display: inline-block;
      }
      & .right {
        display: flex;
        align-items: center;

        & .user-image {
          height: 36px;
          width: 36px;
          object-fit: cover;
          border-radius: 50%;
          margin-top: 4px;
        }
      }
    }
    & .bottom {
      display: none;
    }
  }
`;

const SideBar = styled.div`
  min-height: 100%;
  background-color: #121212;
  z-index: 102;
  position: fixed;
  transition: 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-block: 20px;
  justify-content: space-between;
  width: 104px;
  /* &.open {
    width: 260px;
  }
  &.close {
    width: 80px;
  } */
  & .link {
    text-decoration: none;
    text-transform: none;
  }
  & .nav-links {
    list-style-type: none;
    width: 100%;

    & .active {
      & li::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 2px;
        background-color: ${theme.palette.primary};
      }
      & li {
        background-color: #ffffff1a;
        height: 60px;
        & img {
          opacity: 1;
        }
        & p {
          opacity: 1;
        }
      }
    }
    & li {
      position: relative;
      height: 52px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-block: 5px;
      gap: 7px;
      & img {
        height: 16px;
        width: 16px;
        opacity: 0.4;
      }
      & p {
        line-height: 1;
        left: 48px;
        font-size: 6px;
        transition: 0.3s ease;
        opacity: 0.4;
        color: #ffffff;
      }
    }
    /* & li.close {
      padding-inline: 0px;
      text-align: center;
      justify-content: center;
      & p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
      }
    } */
  }
  & .top-section {
    display: flex;
    & img {
      transition: 0.3s ease;
    }
    justify-content: center;
    .brand-logo {
      height: 44px;
      width: 56px;
      /* margin-left: 14px; */
    }
    .menu-icon {
      margin-top: 28px;
      height: 11px;
      width: 19px;
      margin-left: 30px;
      cursor: pointer;
    }
  }
  & .bottom-section {
    & .divider {
      border-top: 1px solid #ffffff33;
    }
    & .font-size-12 {
      padding-inline: 24px;
      margin-block: 14px 20px;
      min-width: 250px;
    }
  }
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const Content = styled.div`
  height: 100%;
  transition: 0.3s ease;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  /* &.open {
    padding-inline: 260px 0px;
  }
  &.close {
    padding-inline: 80px 0px;
  } */
  padding-inline: 104px 0px;
  @media only screen and (max-width: 700px) {
    padding-block: 60px;
    &.open {
      padding-inline: 0px;
    }
    &.close {
      padding-inline: 0px;
    }
  }
`;

const Menu = styled.div`
  position: relative;
  & .row {
    display: flex;
    align-items: center;
    & p {
      margin-inline: 14px 6px;
    }
  }
  & .menu-popup {
    position: absolute;
    top: 115%;
    right: 0;
    width: 260px;
    background-color: #0e0e0e;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    padding-bottom: 6px;

    & .profile {
      display: flex;
      align-items: center;
      padding: 21px;
      border-bottom: 1px solid #ffffff33;
      & .user-image {
        height: 48px;
        width: 48px;
        object-fit: cover;
        border-radius: 50%;
      }
      & .info {
        margin-left: 11px;
        & p.font-size-14 {
          font-weight: 600;
        }
        & .address-container {
          display: flex;
          align-items: center;
          margin-top: 2px;
          position: relative;
          & img {
            cursor: pointer;
          }
          & p {
            background-color: #ffffff1a;
            padding: 2px;
            border-radius: 2px;
            margin-right: 4px;
          }
          & .tooltip {
            position: absolute;
            left: 105%;
            top: calc(50% - 10px);
            background-color: #ffffff33;
            padding-inline: 5px;
            border-radius: 2px;
            font-size: 10px;
            height: 20px;
            line-height: 16px;
            transform: scale(0.1);
            opacity: 0;
            pointer-events: none;
            transition: 0.2s ease;
            &.show {
              transform: scale(1);
              opacity: 1;
            }
          }
        }
      }
    }
    & .menu-item {
      padding-inline: 20px;
      height: 56px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ffffff33;
      cursor: pointer;
      & .sub-item {
        text-decoration: none;
      }
      & img {
        height: 16px;
        width: 16px;
        margin-right: 10px;
        opacity: 0.4;
      }
      & p {
        opacity: 0.4;
      }
      &:last-child {
        border-bottom: none;
      }
      &.hidden-mobile {
        display: none;
      }
      &.logout {
        border: none;
      }
      & .lougtIcon {
        height: 20px;
        width: 20px;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    & .row {
      & img {
        &:last-of-type {
          display: none;
        }
      }
      & p {
        display: none;
      }
    }
    & .menu-popup {
      width: 240px;
      & .profile {
        display: none;
      }
      & .menu-item {
        &.hidden-mobile {
          display: flex;
        }
        &.logout {
          border-bottom: 1px solid #ffffff33;
        }
      }
    }
  }
`;

const BottomBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #0e0e0e;
  display: none;
  justify-content: center;

  & .tab {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 14px 15px;
    & .tab-label {
      font-size: 8px;
      line-height: 14px;
      color: #ffffff;
      opacity: 0.4;
    }

    & img {
      opacity: 0.4;
      margin-bottom: 2px;
      height: 16px;
      width: 16px;
    }
  }
  & .active {
    & .tab {
      background-color: #ffffff1a;
      & .tab-label {
        opacity: 1;
      }

      & img {
        opacity: 1;
      }
    }
  }
  @media only screen and (max-width: 700px) {
    display: flex;
  }
  @media only screen and (max-width: 357px) {
    & .tab {
      padding: 10px;
      & .tab-label {
        font-size: 6px;
      }
    }
  }
`;

const NotificationsMenu = styled.div`
  /* position: relative; */

  & img.notification-icon {
    cursor: pointer;
  }
`;

const WalletMenu = styled.div`
  width: 224px;
  background-color: #121212;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 10%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding-inline: 10px;

  & .divider {
    border-top: 1px solid #ffffff33;
  }

  & .row {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 16px 8px;
    cursor: pointer;
    & span {
      opacity: 1;
    }
    & img {
      width: 24px;
    }

    &:hover {
      & span {
        opacity: 1;
      }
    }
  }
`;

const BreadCrums = styled.div`
  min-height: 32px;
  display: flex;
  align-items: center;
  border-top: 1px solid #ffffff1a;
  border-bottom: 1px solid #ffffff1a;
  background-color: ${theme.palette.black};
  & p {
    margin-left: 30px;
  }
`;
