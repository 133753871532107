import React, { FC, ChangeEvent, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';

import Page from '../../layouts/Page';
import Select from '../../components/Select/Select';
import VaultItem from '../../components/VaultItem/VaultItem';
import Filters from '../../components/Filters/Filters';
// import Checkbox from '../../components/Checkbox/Checkbox';

import Loader from '../../components/Loader/Loader';

const Vault: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const games = useSelector(
    (state: RootState) => state.games.gamesWithContracts
  );

  const nfts = useSelector((state: RootState) => state.vault.nfts);
  const loading = useSelector((state: RootState) => state.vault.loading);

  const [showCategoryFilter, setShowCategoryFilter] = useState(false);
  // send this cursor in api call for pagination
  const [cursor, setCursor] = useState<null | string>(null);

  const [game, setGame] = useState(games[0]?.game_id || '');
  const [category, setCategory] = useState('');
  const [cateogryOptions, setCategoryOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  // eslint-disable-next-line
  const [filters, setFilters] = useState({
    earning: '',
    revenueSplit: '',
  });

  const [search, setSearch] = useState('');

  const handleGameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setGame(e.currentTarget.value);
    const selectedGame = games.find(
      game => game.game_id === e.currentTarget.value
    );
    const _categoryOptions = selectedGame?.contracts.map(e => ({
      label: e.contract_name,
      value: e.contract_id,
    }));

    if (_categoryOptions) {
      if (_categoryOptions.length > 1) {
        setShowCategoryFilter(true);
      } else {
        setShowCategoryFilter(false);
      }

      setCategory(_categoryOptions[0]?.value);

      setCategoryOptions(_categoryOptions);
    }
  };
  // eslint-disable-next-line
  const HandleFilters = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (!games.length) {
      dispatch.games.gamesWithContracts();
    }
  }, []);

  useEffect(() => {
    let selectedGame;
    if (!game) {
      selectedGame = games[0];
      setGame(selectedGame?.game_id);
    } else {
      selectedGame = games.find(e => e.game_id === game);
    }
    const _categoryOptions = selectedGame?.contracts.map(e => ({
      label: e.contract_name,
      value: e.contract_id,
    }));
    if (_categoryOptions) {
      if (_categoryOptions.length > 1) {
        setShowCategoryFilter(true);
      } else {
        setShowCategoryFilter(false);
      }

      setCategory(_categoryOptions[0]?.value);

      setCategoryOptions(_categoryOptions);
    }
  }, [games]);

  useEffect(() => {
    if (game) {
      if (showCategoryFilter) {
        const payload = {
          data: { contract_id: category },
          cursor: cursor || '',
          setCursor: setCursor,
        };
        dispatch.vault.getNfts(payload);
      } else {
        const selectedGame = games.find(e => e.game_id === game);
        const contract_id = selectedGame?.contracts[0]?.contract_id;

        if (contract_id) {
          const payload = {
            data: { contract_id },
            cursor: cursor || '',
            setCursor: setCursor,
          };
          dispatch.vault.getNfts(payload);
        }
      }
    }
  }, [game, category]);
  return (
    <Page>
      <h1 className='title'>Vault</h1>
      <Filters search={search} setSearch={setSearch}>
        <Select
          name='game'
          title='Select game'
          onChange={handleGameChange}
          options={games.map(game => ({
            label: game.name,
            value: game.game_id,
          }))}
          value={game}
        />

        {showCategoryFilter && (
          <Select
            name='category'
            title='Category'
            onChange={e => setCategory(e.target.value)}
            options={cateogryOptions}
            value={category}
          />
        )}

        {/* <Row>
          <Checkbox /> <p className='font-size-14'>Show Assets Lended to You</p>
        </Row> */}
      </Filters>
      {loading ? (
        <Loader />
      ) : (
        <Grid>
          {nfts?.map((nft, index) => (
            <VaultItem key={index} nftData={nft} />
          ))}
        </Grid>
      )}
    </Page>
  );
};

export default Vault;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(184px, 1fr));
  gap: 24px;
  margin-top: 30px;

  @media only screen and (max-width: 700px) {
    padding-bottom: 80px;
  }

  @media only screen and (max-width: 450px) {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
`;

// const Row = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 10px;
// `;
