import React, { FC, useMemo } from 'react';

import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import assets from '../../assets';

interface IProps {
  image: File | null;
  setImage: React.Dispatch<React.SetStateAction<File | null>>;
}

const ImagePicker: FC<IProps> = ({ image, setImage }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { image: ['.png', '.jpg', '.jpeg'] },
    maxFiles: 1,
    maxSize: 2097152,

    onDrop: acceptedFiles => {
      const file = acceptedFiles[0];
      let extension = file.name.split('.');
      const exten = extension[extension.length - 1];
      if (exten === 'png' || exten === 'jpg' || exten === 'jpeg') {
        setImage(acceptedFiles[0]);
      }
    },
  });

  const baseStyle = {
    height: '160px',
    width: '160px',
    border: '1px dashed #ffffff66',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '50%',
    cursor: 'pointer',
  };

  const activeStyle = {
    border: '1px solid transparent',
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive
        ? activeStyle
        : { border: image ? '0px solid transparent' : '1px dashed #ffffff66' }),

      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragActive, isDragReject, isDragAccept, image]
  );

  return (
    <>
      <Container>
        <div
          {...getRootProps({
            style: style,
          })}
        >
          <input {...getInputProps()} />
          <div className='flex-container'>
            {image ? (
              <img
                src={URL.createObjectURL(image)}
                style={{
                  height: '100%',
                  width: '100%',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
                alt=''
              />
            ) : (
              <>
                {' '}
                <img
                  src={assets.cloudUp}
                  style={{ opacity: '0.4', marginBottom: '10px' }}
                  alt=''
                />
                <p
                  className='font-size-12'
                  style={{ opacity: '0.4', textAlign: 'center' }}
                >
                  Drag and drop or <br /> click to upload
                </p>
              </>
            )}
          </div>
        </div>
      </Container>
      {image && (
        <ImageName className=''>
          <p className='font-size-12 '>{image?.name}</p>
          <img src={assets.quit} alt='' onClick={() => setImage(null)} />
        </ImageName>
      )}
    </>
  );
};

export default ImagePicker;

const ImageName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px;

  & img {
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & .flex-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & p {
      margin-block: 5px 14px;
    }
    & button {
      background-color: #ffffff1a;
      border: none;
      padding: 4px 20px;
      border-radius: 2px;
      cursor: pointer;
    }
  }
  & .row {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-inline: 10px;
    justify-content: center;
    flex-wrap: wrap;
  }
`;
